import { createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { API_URL } from "../../utils/constants";

const initialState: GameReducerInitialState = {
    loading: false,
    list: [],
    filtered: [],
    error: null,
};
const gamesSlice = createSlice({
    name: "games",
    initialState,
    reducers: {
        filterGames(state, action) {
            const filtered = state.list.filter((game) => {
                return game.name
                    .toLowerCase()
                    .includes(action.payload.toLowerCase());
            });
            state.filtered = filtered;
        },
        updateGames: (state, action) => {
            state.list = action.payload;
            state.filtered = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers: (builder) => {
        // builder.addCase(getStudioGames.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(getStudioGames.fulfilled, (state, action) => {
        //     console.log("GAMES FULLFILLED : ", action);
        //     state.loading = false;
        //     state.filtered = action.payload;
        //     state.list = action.payload;
        // });
        // builder.addCase(getStudioGames.rejected, (state, action) => {
        //     console.log("HERE>>...");
        //     state.error = action.payload as string;
        //     state.loading = false;
        // });
    },
});

/// ---------- GAMES ----------
export const getStudioGames = async (payload: any): Promise<any> => {
    const resp = await api.post("/game/", {
        pageNumber: payload.pageNumber || 1,
        pageSize: payload.pageSize || 100,
        sortField: payload.sortField || "id",
        sortOrder: payload.sortOrder || "desc",
        filter: payload.filter,
    });

    return resp;
};

export const saveNewGame = async (payload: any): Promise<APIResponse> => {
    const requestBody = {
        name: payload.name,
        description: payload.description,
        logo: payload.logo,
        background_img: payload.background_img,
        config: payload.config,
        category_ids: payload.category_ids || [],
        slug: payload.slug || "test",
        ...(payload.is_android && { is_android: true }),
        ...(payload.is_ios && { is_ios: true }),
    };

    const response: APIResponse = await api.post(
        `${API_URL}/game/add`,
        requestBody
    );

    const { success, result } = response;

    return { success, result };
};

export const fetchMonthlyActiveUser = async (payload: any): Promise<APIResponse> => {
      const { success, monthly_user_count, game_id }: APIResponse = await api.post(
        "/game/monthly_active_user",
        {
            start_date:payload.start_date,
            end_date:payload.end_date,
            game_id:payload.game_id
        }
    );
    return { success,  monthly_user_count , game_id  };
};


export const fetchTotalDistributedCoin = async (
  payload: any
): Promise<APIResponse> => {
  const { success, distributed_coin, studio_id }: APIResponse = await api.post(
    "/game/distributed_coin",
    {
      start_date: payload.start_date,
      end_date: payload.end_date,
      studio_id: payload.studio_id,
    }
  );
  return { success, distributed_coin, studio_id };
};

export const updateGame = async (payload: {
    gameId: number;
    data: any;
}): Promise<APIResponse> => {
    const response: APIResponse = await api.put(
      `${API_URL}/game/${payload.gameId}`,
      payload.data
    );

    const { success } = response;

    if (!success) {
        return { success: false };
    }

    return { success };
};

export const updateProfilePictures = async (payload: {
  id: number;
  images: Object[];
}): Promise<APIResponse> => {
  const response: APIResponse = await api.post(
    `${API_URL}/game/add_profile_image`,
    payload
  );

  const { success, message } = response;

  

  return { success, message };
};

export const getProfilePictures = async (payload: {
  game_id: number;
}): Promise<APIResponse> => {
   const response: APIResponse = await api.post(
     `${API_URL}/game/profile_images/${payload.game_id}`
   );

  const { success, message, images } = response;

  return { success, message, images };
};

export const deleteGame = async (payload: { gameId: number }) => {
    const response: APIResponse = await api.delete(
      `${API_URL}/game/${payload.gameId}`
    );

    const { success } = response;

    if (!success) {
        return { success: false };
    }

    return { success };
};

/// ---------- TOURNAMENTS ----------
/// GET:
export const getTournamentsList = async (payload: any): Promise<any> => {
    const { success, total_count, result }: APIResponse = await api.post(
        "/tournament",
        {
            pageNumber: payload.pageNumber || 1,
            pageSize: payload.pageSize || 10,
            sortField: payload.sortField || "id",
            sortOrder: payload.sortOrder || "desc",
            filter: payload.filter,
        }
    );

    return { success, total_count, result };
};

export const getCashTournamentsList = async (payload: any): Promise<any> => {
  const { success, total_count, result }: APIResponse = await api.post(
    "/tournament/cash_tournament/list",
    {
      pageNumber: payload.pageNumber || 1,
      pageSize: payload.pageSize || 10,
      sortField: payload.sortField || "id",
      sortOrder: payload.sortOrder || "desc",
      filter: payload.filter,
    }
  );

  return { success, total_count, result };
};


export const postCashTournamentUsers = async (payload: any): Promise<any> => {
  const { success, message }: APIResponse = await api.post(
    "/tournament/cash_tournament/distribute_rewards",
    {
      tournament_id: payload.tournament_id,
      rewards: payload.rewards,
    }
  );

  return { success, message};
};

/// ADD :
export const addTournament = async (payload: {
  name: string;
  start_time: string;
  end_time: string;
  status: number;
  entry_price: number;
  game_id: number;
  tournament_rewards: {
    starting_rank: number;
    ending_rank: number;
    xp: number;
    coin: number;
  }[];
}): Promise<any> => {
  const response: APIResponse = await api.post("/tournament/add", payload);
  const { success, result, message } = response;
  return { success, result, message };
};

export const addCashTournament = async (payload: {
  name: string;
  start_time: string;
  end_time: string;
 ticket_price: number;
    description: string;
    studio_id: number;
    created_by: number;
    // stripe_key: string;

}): Promise<any> => {
  const response: APIResponse = await api.post("/tournament/cash_tournament", payload);
  const { success, result, message } = response;
  return { success, result, message };
};

export const editCashTournament = async (payload: {
  name: string;
  start_time: string;
  end_time: string;
  ticket_price: number;
  description: string;
  studio_id: number;
    created_by: number;
    id: number;
  // stripe_key: string;
}): Promise<any> => {
  const response: APIResponse = await api.put(
    `/tournament/cash_tournament/${payload.id}`,
    payload
  );
  const { success, result, message } = response;
  return { success, result, message };
};

export const deleteCashTournament = async (payload: {
  id: number;
  // stripe_key: string;
}): Promise<any> => {
  const response: APIResponse = await api.delete(
    `/tournament/cash_tournament/${payload.id}`
  );
  const { success, result, message } = response;
  return { success, result, message };
};

/// EDIT :
export const editTournament = async (payload: {
  name: string;
  start_time: string;
  end_time: string;
  status: number;
  entry_price: number;
  game_id: number;
  id: number;
  tournament_rewards: {
    starting_rank: number;
    ending_rank: number;
    xp: number;
    coin: number;
  }[];
}): Promise<any> => {
  const { success, result, message }: APIResponse = await api.put(
    `/tournament/${payload.id}`,
    payload
  );
  return { success, message, result };
};

/// DELETE :
export const deleteTournament = async (payload: {
    id: number;
}): Promise<any> => {
    const { success }: APIResponse = await api.delete(
        `/tournament/${payload.id}`
    );
    return { success };
};

/// ---------- TOURNAMENT REWARDS ----------
/// LIST:
export const getRewardsList = async (payload: {
    tournament_id: number;
}): Promise<any> => {
    const { success, total_count, result }: APIResponse = await api.post(
        "/tournament/tournament_reward/list",
        {
            pageNumber: 1,
            pageSize: 100,
            sortField: "id",
            sortOrder: "desc",
            filter: { tournament_id: payload.tournament_id, active: true },
        }
    );
    return { success, total_count, result };
};
/// ADD:
export const addTournamentReward = async (payload: {
    starting_rank: number;
    ending_rank: number;
    xp: number;
    tournament_id: number;
    coin: number;
}): Promise<any> => {
    const response: APIResponse = await api.post(
        "/tournament/tournament_reward/add",
        payload
    );
    const { success } = response;
    return { success };
};
/// DELETE:
export const deleteTournamentReward = async (payload: {
    reward_id: number;
}) => {
    const { success }: APIResponse = await api.delete(
        `/tournament/tournament_reward/${payload.reward_id}`
    );
    return { success };
};

/// ---------- REGION FACTORS ----------

/// ---------- LEVEL REWARDS ----------
class LevelRewards {
    async List(id: number): Promise<any> {
        const { success, total_count, result }: APIResponse = await api.post(
            "/game/game_level/list",
            {
                pageNumber: 1,
                pageSize: 10,
                sortField: "id",
                sortOrder: "desc",
                filter: { game_id: id, active: true },
            }
        );

        if (success) {
            return { success, total_count, result };
        } else {
            return { success };
        }
    }

    async Add(payload: LevelReward): Promise<any> {
        const { success, message }: APIResponse = await api.post(
            "/game/game_level/add",
            payload
        );

        return { success, message };
    }

    async Edit(payload: any): Promise<any> {
        const { success }: APIResponse = await api.put(
            `/game/game_level/${payload.id}`,
            payload
        );
        return { success };
    }

    async Delete(payload: any): Promise<any> {
        const { success }: APIResponse = await api.delete(
            `/game/game_level/${payload.id}`
        );
        return { success };
    }
}
export const levelRewards = new LevelRewards();

/// ---------- REGION FACTORS ----------
class RegionFactors {
    async List(id: number): Promise<any> {
        const { success, total_count, result }: APIResponse = await api.post(
            "/game/region_factor/list",
            {
                pageNumber: 1,
                pageSize: 10,
                sortField: "id",
                sortOrder: "desc",
                filter: { game_id: id, active: true },
            }
        );

        if (success) {
            return { success, total_count, result };
        } else {
            return { success };
        }
    }

    async Add(payload: {}): Promise<any> {
        const { success }: APIResponse = await api.post(
            "/game/region_factor/add",
            payload
        );

        return { success };
    }

    async Edit(): Promise<any> {}

    async Delete(payload: any): Promise<any> {
        const { success }: APIResponse = await api.put(
            `/game/region_factor/${payload.id}`,
            payload
        );
        return { success };
    }
}
export const regionFactors = new RegionFactors();

/// ---------- REWARD LIMIT ----------
class RewardLimits {
    async List(game_id: number) {
        const { success, total_count, result }: APIResponse = await api.post(
            "/reward/reward_limit/list",
            {
                pageNumber: 1,
                pageSize: 10,
                sortField: "id",
                sortOrder: "desc",
                filter: { game_id },
            }
        );
        return { success, total_count, result };
    }
    async Update(rewardId: number, game_id: number, daily_limit: number) {
        const { success, result }: APIResponse = await api.put(
            `/reward/reward_limit/update/${rewardId}`,
            {
                game_id,
                daily_limit,
            }
        );

        return { success, result };
    }

    async Add(game_id: number, daily_limit: number) {
        const { success, result }: APIResponse = await api.post(
            "/reward/reward_limit/add",
            {
                game_id,
                daily_limit,
            }
        );
        return { success, result };
    }
}
export const rewardLimits = new RewardLimits();

/// ---------- ALLOCATIONS ----------
export const saveGameAllocation = async (payload: {
    game_id: number;
    distributed_coin: number;
}): Promise<any> => {
    const { success, message }: APIResponse = await api.put(
      `/game/allocation/${payload.game_id}`,
      { allocated_coin: payload.distributed_coin }
    );
    return { success, message };
};

export const { filterGames, updateGames, setLoading } = gamesSlice.actions;
export default gamesSlice.reducer;
