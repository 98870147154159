import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Row,
    message,
    Steps,
    Table,
    Space,
    Popconfirm
} from "antd";
import Layout from "../../../../../../components/Layout";
import PageContent from "../../../../../../components/PageContent";
import PageHeader from "../../../../../../components/PageHeader";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { useForm } from "antd/es/form/Form";
import {
    CheckCircleOutlined,
    PlusOutlined,
    DeleteOutlined

} from "@ant-design/icons";
import { useEffect, useState, ReactElement } from "react";
import { addDaysToTimestamp, convertUtcToLocal } from "../../../../../../utils/helpers";
import {
    editCashTournament, getCashTournamentsList
} from "../../../../../../store/slices/games";
import { useSelector } from "react-redux";
import type { RangePickerProps } from "antd/es/date-picker";
import TextArea from "antd/es/input/TextArea";



export default function EditCashTournament() {
    const { ui } = useSelector((state: GlobalState) => state);
    const { user } = useSelector((state: GlobalState) => state.auth);
    const location = useLocation();
    const { selectedGame } = ui;
    const [form] = useForm();
    const navigate = useNavigate();
    const [tournamentsList, setTournamentsList] = useState<any[]>([]);
    const [selectedDate, setSelectedDate] = useState<Dayjs>();
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [addMode, setAddMode] = useState<boolean>(false);

    const tournamentInitialValue = {
        name: "",
        start_date_timestamp: "",
        duration: 0,
        ticket_price: 0,
        description: "",
    }
    const [tournamentInfo, setTournamentInfo] = useState<TournamentInfo>(tournamentInitialValue)
    const [rewards, setRewards] = useState<RewardRow[] | null>(null);
    const [rewardRow, setRewardRow] = useState<RewardRow>({
        placement: "0",
        from: "0",
        to: "0",
        xp: "0",
        point: "0",
        actions: (
            <>
                <Button>Del</Button>
            </>
        ),
    });
    type TournamentInfo = {
        name: string;
        start_date_timestamp: any;
        duration: number;
        ticket_price: number;
        description: string;
    }

    type RewardRow = {
        placement: string;
        from: string;
        to: string;
        xp: string;
        point: string;
        actions: ReactElement;
    };


    useEffect(() => {
        getTournaments();
        const date1 = dayjs(location.state.start_time);
        const date2 = dayjs(location.state.end_time);
        const duration = date2.diff(date1, "day");
        setTournamentInfo({ name: location.state.name, start_date_timestamp: location.state.start_time, duration: duration, ticket_price: location.state.ticket_price, description: location.state.description })
        form.setFieldValue('name', location.state.name);
        form.setFieldValue('start_time', dayjs(location.state.start_time));
        form.setFieldValue('duration', duration);
        form.setFieldValue('ticket_price', location.state.ticket_price);
        form.setFieldValue('description', location.state.description);

    }, [location.state]);


    const getTournaments = async () => {
        const { success, total_count, result } = await getCashTournamentsList({
            filter: {
                studio_id: selectedGame?.studio?.id,
                game_id: selectedGame?.id,
                active: true,
            },
        });
        if (success && total_count > 0) {
            setTournamentsList(result);
        }
    };


    const deleteRewardRow = (placement: string) => {
        const newRewards = rewards?.filter((item) => item.placement !== placement);
        setRewards(newRewards || []);
    };

    const saveTournament = async (values: any) => {
        const { duration, ticket_price, description, name, start_date_timestamp } = tournamentInfo;
        if (duration <= 0 || !duration) {
            message.error("Length can not be less than 1");
            return false;
        }

        // if (!stripe_key) {
        //     message.error("You have to provide your stripe key");
        //     return false;
        // }

        if (!ticket_price || ticket_price <= 0) {
            message.error("Ticket price must be positive");
            return false;
        }

        const start_time = new Date(start_date_timestamp).getTime();
        const end_date = addDaysToTimestamp(
            start_time,
            duration
        );

        // setTournamentInfo({ ...tournamentInfo, name, start_date_timestamp, end_date, description, stripe_key, ticket_price })




        if (selectedGame) {
            if (!name || !start_date_timestamp || !end_date || !ticket_price) {
                message.error("Enter the required fields");
                return;
            }
            const res = await editCashTournament({
                id: location.state.id,
                name,
                start_time:
                    new Date(start_date_timestamp).toLocaleString("en-US", {
                        timeZone: "UTC",
                    }) + "",
                end_time:
                    new Date(end_date).toLocaleString("en-US", {
                        timeZone: "UTC",
                    }) + "",
                ticket_price: ticket_price,
                description: description,
                studio_id: selectedGame?.studio?.id || 0,
                created_by: user?.id || 0,
                // stripe_key: stripe_key
            });

            if (!res.success) {
                message.error(res.message);

            }

            if (res.success) {
                message.success("Tournament is added")
                navigate("/events/cash-tournaments");
            }
        }
    };


    // eslint-disable-next-line arrow-body-style
    const disabledDate: RangePickerProps["disabledDate"] = (current) => {
        return (current && current < dayjs().add(-1, 'day').endOf('day'))
            || tournamentsList.findIndex(t => dayjs(current).isBetween(convertUtcToLocal(t.raw_start_time), convertUtcToLocal(t.raw_end_time), 'date', '[)')) !== -1;
    };


    //time disable
    const getDisabledHours = () => {
        var hours = [];
        if (dayjs(selectedDate).format('YYYY.MM.DD') === dayjs().format('YYYY.MM.DD')) {
            for (let i = 0; i < dayjs().hour(); i++) {
                hours.push(i);
            }
        }

        if (tournamentsList.findIndex(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day')) !== -1) {
            const tournamentStart = tournamentsList.find(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_start_time);
            for (let i = dayjs(start).hour(); i < 24; i++) {
                hours.push(i);
            }

        } else if (tournamentsList.findIndex(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day')) !== -1) {
            const tournamentStart = tournamentsList.find(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_end_time);
            for (let i = 0; i < dayjs(start).hour(); i++) {
                hours.push(i);
            }
        }
        return hours;
    }

    //time disable
    const getDisabledMinutes = (selectedHour: any) => {
        var minutes = [];
        if (selectedHour === dayjs().hour()) {
            for (let i = 0; i <= dayjs().minute(); i++) {
                minutes.push(i);
            }
        }
        if (tournamentsList.findIndex(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day')) !== -1) {
            const tournamentStart = tournamentsList.find(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_start_time);
            if (dayjs(start).hour() === selectedHour) {
                for (let i = dayjs(start).minute(); i < 60; i++) {
                    minutes.push(i);
                }
            }


        } else if (tournamentsList.findIndex(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day')) !== -1) {
            const tournamentStart = tournamentsList.find(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_end_time);
            if (dayjs(start).hour() === selectedHour) {
                for (let i = 0; i <= dayjs(start).minute(); i++) {
                    minutes.push(i);
                }
            }
        }

        return minutes;
    }


    const isTotalRewardExceed = () => {
        const totalReward =
            rewards?.reduce((ac, current) => {
                const { to, from, point } = current;
                return ac + (parseFloat(to) - parseFloat(from) + 1) * parseFloat(point);
            }, 0) || 0;

        const { to, from, point } = rewardRow;
        let otherTournamentCosts = 0;
        tournamentsList.forEach((t) => {
            const tournamentCost = t?.rewards?.reduce((ac: number, current: any) => {
                const { ending_rank, starting_rank, coin } = current;
                return ac + (ending_rank - starting_rank + 1) * parseFloat(coin);
            }, 0);
            otherTournamentCosts += tournamentCost;
        });
        const rewardRowCost =
            (parseFloat(to) - parseFloat(from) + 1) * parseFloat(point);

        if (
            selectedGame?.total_coin &&
            totalReward + rewardRowCost <=
            +selectedGame.total_coin - otherTournamentCosts
        )
            return true;
        return false;
    };

    const checkAddedBefore = (from: string, to: string) => {
        if (!rewards) {
            return false;
        }
        const match = rewards
            ? rewards.filter((rev: any) => {
                if (
                    +rev.from === +from ||
                    +rev.to === +to ||
                    (+from <= +rev.from && +to <= +rev.to && +to > +rev.from) ||
                    (+from >= +rev.from && +to <= +rev.to) ||
                    (+from >= +rev.from && +to >= +rev.to && +from <= +rev.to)
                ) {
                    return rev;
                }
            })
            : [];

        return match.length > 0;
    };

    const setTournamentDetails = (values: any) => {
        if (!values.name && !values.start_time && !values.duration) {
            message.error("Please fill required fields");
            return false;
        }
        if (!values.start_time) {
            message.error("Please select a date");
            return false;
        }
        if (values.duration <= 0 || !values.duration) {
            message.error("Length can not be less than 1");
            return false;
        }

        if (values.name === "" || !values.name) {
            message.error("Tournament title must be added.");
            return false;
        }

        const start_date_timestamp = new Date(values.start_time).getTime();
        const end_date = addDaysToTimestamp(start_date_timestamp, values.duration);

        const newTournamentInfo = {
            name: values.name,
            start_date_timestamp,
            duration: values.duration,
            status: 2,
            ticket_price: values.ticket_price,
            description: values.description,
        };

        setTournamentInfo(newTournamentInfo);

        if (currentStep === 0) {
            setCurrentStep(1);
        }

        if (currentStep === 1 && !rewards) {
            message.error("Tournament can not be added without rewards");
        }
    };

    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        { title: <Link to="/">Home</Link> },
                        {
                            title: (
                                <Link to="/events/cash-tournaments">Cash Tournaments</Link>
                            ),
                        },
                        { title: "Add New Cash Tournament" },
                    ]}
                />
                <Button
                    key="submit"
                    htmlType="submit"
                    onClick={saveTournament}
                    size="large"
                    type="primary"
                    icon={<CheckCircleOutlined />}
                    disabled={currentStep === 0}
                >
                    Save
                </Button>
                {/* </Link> */}
            </PageHeader>
            <PageContent>
                <Row style={{ width: 800 }}>
                    <Col span={4}></Col>
                    <Col span={18}>
                        <Steps
                            style={{ width: 800 }}
                            current={currentStep}
                            onChange={(stp) => {
                                setCurrentStep(stp);
                            }}
                            items={[
                                {
                                    title: "Details",
                                    description: "Set tournament details",
                                },
                                {
                                    title: "Rewards",
                                    description: "Set reward levels",
                                },
                            ]}
                        />
                    </Col>
                </Row>
                <br />
                <br />
                {currentStep === 0 && (
                    <Row>
                        <Col span={3}></Col>
                        <Col span={12}>
                            <Form
                                layout="vertical"
                                name="newTournamentForm"
                                form={form}
                                onFinish={setTournamentDetails}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                style={{ maxWidth: 800 }}
                            >
                                <Form.Item name="name" label="Title">
                                    <Input placeholder="Title of Tournament" />
                                </Form.Item>

                                <Form.Item name="start_time" label="Start time">
                                    <DatePicker
                                        format="YYYY-MM-DD HH:mm:ss"
                                        disabledDate={disabledDate}
                                        onSelect={(d) => setSelectedDate(d)}
                                        showTime={{
                                            disabledHours: getDisabledHours,
                                            disabledMinutes: getDisabledMinutes,
                                        }}
                                        changeOnBlur={true}
                                    />
                                </Form.Item>

                                <Form.Item name="duration" label="Length">
                                    <InputNumber addonAfter={"days"} />
                                </Form.Item>

                                <Form.Item name="ticket_price" label="Ticket Price">
                                    <InputNumber placeholder="Ticket Price" addonAfter={"$"} />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Next
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                )}
                {currentStep === 1 && (
                    <Row>
                        <Col span={3}></Col>
                        <Col span={12}>
                            <Table
                                pagination={false}
                                summary={() => (
                                    <>
                                        {addMode && (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}>
                                                    <Row gutter={9}>
                                                        <Col span={12}>
                                                            <Input
                                                                placeholder="From"
                                                                onChange={(e) => {
                                                                    setRewardRow({
                                                                        ...rewardRow,
                                                                        from: e.target.value,
                                                                        placement: `${e.target.value} - ${rewardRow.to}`,
                                                                    });
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <Input
                                                                placeholder="To"
                                                                onChange={(e) => {
                                                                    setRewardRow({
                                                                        ...rewardRow,
                                                                        to: e.target.value,
                                                                        placement: `${rewardRow.from} - ${e.target.value}`,
                                                                    });
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                    <Input
                                                        placeholder="XP"
                                                        onChange={(e) => {
                                                            setRewardRow({
                                                                ...rewardRow,
                                                                xp: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>
                                                    <InputNumber
                                                        placeholder="Pool Percent"
                                                        addonAfter="%"
                                                        onChange={(e) => {
                                                            setRewardRow({
                                                                ...rewardRow,
                                                                point: e?.toString() + " %",
                                                            });
                                                        }}
                                                    />
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        )}
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0}>
                                                {!addMode && (
                                                    <Button
                                                        type="primary"
                                                        icon={<PlusOutlined />}
                                                        onClick={() => {
                                                            setAddMode(true);
                                                        }}
                                                        disabled={addMode}
                                                    >
                                                        Add new row
                                                    </Button>
                                                )}
                                                {addMode && (
                                                    <Space>
                                                        <Button
                                                            type="primary"
                                                            onClick={async () => {
                                                                const addedBefore = await checkAddedBefore(
                                                                    rewardRow.from,
                                                                    rewardRow.to
                                                                );
                                                                // if (!isTotalRewardExceed()) {
                                                                //     message.warning("For adding more rewards, please allocate more amount for the game.");
                                                                //     return false;

                                                                // }

                                                                if (
                                                                    +rewardRow.from <= 0 ||
                                                                    +rewardRow.to <= 0
                                                                ) {
                                                                    message.warning(
                                                                        "Values can not be less than 1"
                                                                    );
                                                                    return false;
                                                                } else if (+rewardRow.from > +rewardRow.to) {
                                                                    message.warning(
                                                                        "'From' field can not be bigger than 'To' field"
                                                                    );
                                                                    return false;
                                                                } else if (parseFloat(rewardRow.point) <= 0) {
                                                                    message.warning(
                                                                        "Point amount should be positive."
                                                                    );
                                                                    return false;
                                                                }

                                                                if (addedBefore) {
                                                                    message.error(
                                                                        "Row conflict, please check your player rows!"
                                                                    );
                                                                    return false;
                                                                }

                                                                if (rewards) {
                                                                    setRewards([...rewards, rewardRow]);
                                                                } else {
                                                                    setRewards([rewardRow]);
                                                                }

                                                                // const {
                                                                //     success,
                                                                // } =
                                                                //     await addTournamentReward(
                                                                //         {
                                                                //             starting_rank:
                                                                //                 +rewardRow.from,
                                                                //             ending_rank:
                                                                //                 +rewardRow.to,
                                                                //             xp: +rewardRow.xp,
                                                                //             tournament_id:
                                                                //                 newTournamentId as number,
                                                                //             coin: +rewardRow.point,
                                                                //         }
                                                                //     );
                                                                // if (success) {
                                                                //     setAddMode(
                                                                //         false
                                                                //     );
                                                                //     setRewardRow(
                                                                //         {
                                                                //             placement:
                                                                //                 "0",
                                                                //             from: "0",
                                                                //             to: "0",
                                                                //             xp: "0",
                                                                //             point: "0",
                                                                //             actions:
                                                                //                 (
                                                                //                     <>

                                                                //                     </>
                                                                //                 ),
                                                                //         }
                                                                //     );
                                                                // } else {
                                                                //     message.error(
                                                                //         "New reward rule error!"
                                                                //     );
                                                                // }
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setAddMode(false);
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Space>
                                                )}
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                )}
                                columns={[
                                    {
                                        title: "Winner Count",
                                        dataIndex: "placement",
                                        key: "placement",
                                    },
                                    {
                                        title: "XP",
                                        dataIndex: "xp",
                                        key: "xp",
                                        width: 150,
                                    },
                                    {
                                        title: "Pool Percent",
                                        dataIndex: "point",
                                        key: "point",
                                        width: 150,
                                    },
                                    {
                                        title: "Actions",
                                        dataIndex: "actions",
                                        key: "actions",
                                        align: "right",
                                        width: 150,
                                        render: (_, record) => {
                                            return (
                                                <>
                                                    {/* <Button
                                                        size="small"
                                                        icon={<EditOutlined />}
                                                    ></Button>{" "} */}
                                                    <Popconfirm
                                                        title="Delete the reward row"
                                                        description="Are you sure to delete this reward row?"
                                                        onConfirm={() => {
                                                            deleteRewardRow(`${record.from} - ${record.to}`);
                                                        }}
                                                        onCancel={() => { }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            size="small"
                                                            icon={<DeleteOutlined />}
                                                        ></Button>
                                                    </Popconfirm>
                                                </>
                                            );
                                        },
                                    },
                                ]}
                                dataSource={rewards || []}
                            ></Table>
                        </Col>
                    </Row>
                )}
            </PageContent>
        </Layout>
    );
}