// import axios from "axios";
// const ENDPOINTS = {
//     login: "/authenticate/back-office/login",
// };

// export default axios.create({
//     // baseURL: "https://api.ratic.network",
//     baseURL: "https://api-dev.ratic.network",
// });

import axios from "axios";
import { logOut } from "../store/slices/auth";
import { API_URL } from "./constants";


let store: any;

export const injectStore = (_store: any) => {
    store = _store;
};

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "s-maxage=0",
    },
});

const response = async (response: any) => {
    return Promise.resolve(response?.data);
};

instance.interceptors.response.use(response, async (error) => {
    if (error.response.status === 401) {
        store.dispatch(logOut(1));
    }
    return Promise.reject(error);
});

const requestSuccess = async (config: any) => {
    if (config.url !== "/authenticate/back-office/login") {
        const {
            auth: {
                tokens: { access_token },
            },
        } = store.getState();
        if (access_token) {
            config.headers.Authorization = `Bearer ${access_token}`;
        }
    }
    config.url = config.url + `?t=${new Date().getTime()}`;
    return config;
};
const requestError = async (error: string) => {
    return Promise.reject(error);
};

instance.interceptors.request.use(requestSuccess, requestError);

export default instance;
