import { Button, Checkbox, Form, Input, message as msg } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import api from "../../../../utils/api";
import { useDispatch } from "react-redux";
import { setTokens, setUser } from "../../../../store/slices/auth";
import { setActivePage } from "../../../../store/slices/ui";
import { useNavigate } from "react-router-dom";

export default function LoginForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onFinish = async (values: { username: string; password: string }) => {
        const response = await api.post("/authenticate/back-office/login", {
            email: values.username,
            password: values.password,
        });
        response.data = response;
        const { success, tokens, user, message } = await response.data;

        if (success) {
            try {
                user && dispatch(setUser(user));
                tokens && dispatch(setTokens(tokens));
                dispatch(setActivePage("manage-games"));
            } catch (err: any) {
                throw new Error(err.message);
            }

            navigate("/games/manage-games");
        } else {
            /// login was not successfull
            try {
                dispatch(setUser(null));
                dispatch(setTokens(null));
            } catch (err: any) {
                console.log(err.message);
            }

            msg.error(message);
        }
    };

    return (
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Form.Item
                name="username"
                rules={[
                    { required: true, message: "Please input your Username!" },
                ]}
            >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                    size="large"
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    { required: true, message: "Please input your Password!" },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    size="large"
                />
            </Form.Item>
            <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" href="">
                    Forgot password
                </a>
            </Form.Item>

            <Form.Item>
                <Button
                    block
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                >
                    Sign in
                </Button>
            </Form.Item>
        </Form>
    );
}
