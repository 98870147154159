import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Row,
    Space,
    Steps,
    Table,
    message,
} from "antd";
import Layout from "../../../../../../components/Layout";
import PageContent from "../../../../../../components/PageContent";
import PageHeader from "../../../../../../components/PageHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { useForm } from "antd/es/form/Form";
import {
    CheckCircleOutlined,
    PlusOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { ReactElement, useEffect, useState } from "react";
import { addDaysToTimestamp, convertLocalToUTC, convertUtcToLocal } from "../../../../../../utils/helpers";
import {
    addTournamentReward,
    deleteTournamentReward,
    editTournament,
    getRewardsList,
    getTournamentsList
} from "../../../../../../store/slices/games";
import { useSelector } from "react-redux";
import type { RangePickerProps } from "antd/es/date-picker";

export default function EditTournament() {
    const { ui } = useSelector((state: GlobalState) => state);
    const location = useLocation();
    const { selectedGame } = ui;
    const [form] = useForm();
    const navigate = useNavigate();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [rewards, setRewards] = useState<any>(null);
    const tournamentInitialValue = {
        name: "",
        start_date_timestamp: "",
        end_date: "",
        status: 2,
        entry_price: 0,
        id: location?.state?.id || 0
    }
    const [tournamentInfo, setTournamentInfo] = useState<TournamentInfo>(tournamentInitialValue)
    const [rewardRow, setRewardRow] = useState<RewardRow>({
        placement: "0",
        from: "0",
        to: "0",
        xp: "0",
        point: "0",
        actions: (
            <>
                <Button>Del</Button>
            </>
        ),
    });

    type RewardRow = {
        placement: string;
        from: string;
        to: string;
        xp: string;
        point: string;
        actions: ReactElement;
        id?: number;
    };

    type TournamentInfo = {
        name: string;
        start_date_timestamp: any;
        end_date: any;
        status: number;
        entry_price: number;
        id: number;
    }
    const [formData, setFormData] = useState<any>(null);
    const [tournamentsList, setTournamentsList] = useState<any[]>([]);
    const [selectedDate, setSelectedDate] = useState<Dayjs>();

    useEffect(() => {
        getTournaments();
    }, []);

    useEffect(() => {
        getRewards();
        const date1 = dayjs(location.state.raw_start_time);
        const date2 = dayjs(location.state.raw_end_time);
        const duration = date2.diff(date1, "day");
        setFormData({
            name: location.state.name,
            start_time: dayjs(convertUtcToLocal(location.state.raw_start_time)),
            duration,
        });
        setTournamentInfo(
            {
                name: location.state.name,
                start_date_timestamp: new Date(location.state.raw_start_time).getTime(),
                end_date: new Date(location.state.raw_end_time).getTime(),
                entry_price: location.state.entry_price,
                id: location.state.id,
                status: location.state.status === 'ongoing' ? 2 : location.state.status === "upcoming" ? 1 : 3
            })
    }, [location.state]);

    const deleteRewardRow = async (reward: any) => {
        if (rewards.length === 1) {
            message.warning("Tournament can not be saved without reward, in order to delete,please add another reward first");
            return;
        }
        if (reward.id) {
            const { success } = await deleteTournamentReward({ reward_id: reward.id });
            if (success) {
                message.success("Reward row deleted successfully");
                getRewards();
            }
        } else {
            const otherRewardRows = rewards.filter((r: any) => r.from !== reward.from && r.to !== reward.to && r.placement !== reward.placement);
            setRewards(otherRewardRows)
        }

    };

    const addNewRewards = async (reward: RewardRow) => {
        const { from, to, xp, point, } = reward;
        const { success} =await addTournamentReward({
            starting_rank: Number(from),
            ending_rank: Number(to),
            xp: Number(xp),
            tournament_id: location.state.id,
            coin:Number(point)
        })

        if (success) {
            if (rewards) {
                setRewards([
                    ...rewards,
                    rewardRow,
                ]);
            } else {
                setRewards([
                    rewardRow,
                ]);
            }
            
            
        } else {
            message.error("Something went wrong while adding reward. please try again.");
        }
    }

    const checkAddedBefore = (from: string, to: string) => {

        const match = rewards ? rewards.filter((rev: any) => {
            if (+rev.from === +from || +rev.to === +to ||
                (+from <= +rev.from && +to <= +rev.to && +to > +rev.from) || (+from >= +rev.from && +to <= +rev.to) || (+from >= +rev.from && +to >= +rev.to && +from<= +rev.to)) {
                return rev;
            }
        }) : [];

        return match.length > 0;
    };

    const setTournamentDetails = (values: any) => {
        if (values.duration <= 0) {
            message.error("Length can not be less than 1");
            return false;
        }

        if (values.name === "") {
            message.error("Tournament title must be added.");
            return false;
        }



        const start_date_timestamp = new Date(convertLocalToUTC(values.start_time)).getTime();
        const end_date = addDaysToTimestamp(
            start_date_timestamp,
            values.duration
        );

        const newTournamentInfo = {
            ...tournamentInfo,
            name: values.name, start_date_timestamp, end_date, status: 2, entry_price: 0,
        }

        setTournamentInfo(newTournamentInfo)

        if (currentStep === 0) {
            setCurrentStep(1);
        }

        if (currentStep === 1 && !rewards) {
            message.error("Tournament can not be added without rewards");
        }
    }

    const saveTournament = async (values: any) => {
        if (values.duration <= 0) {
            message.error("Length can not be less than 1");
            return false;
        }
        if (rewards === null) {
            message.error("Tournament can not be added without rewards");
            return false;
        }
        const start_date_timestamp = await new Date(convertLocalToUTC(values.start_time)).getTime();
        // const end_date = addDaysToTimestamp(
        //     start_date_timestamp,
        //     values.duration
        // );

        // if (currentStep === 0) await setTournamentInfo({ ...tournamentInfo, name: values.name, start_date_timestamp, end_date })


        const tournamentRewards = rewards.map((reward: any) => ({ starting_rank: Number(reward.from), ending_rank: Number(reward.to), coin: Number(reward.point), xp: Number(reward.xp) }))


        if (selectedGame) {
            const { name, start_date_timestamp, end_date, status, entry_price, id } = tournamentInfo;
            if (!name || !start_date_timestamp || !end_date) {
                message.error("Enter the required fields");
                setCurrentStep(0);
            }
            const res = await editTournament({
                name,
                start_time:
                    new Date(start_date_timestamp).toLocaleString("en-US") + "",
                end_time:
                    new Date(end_date).toLocaleString("en-US") + "",
                status,
                id,
                entry_price,
                game_id: +selectedGame?.id,
                tournament_rewards: tournamentRewards
            });

            if (!res.success) {
                message.error(res.message);
                setCurrentStep(0);
                setTournamentInfo(tournamentInitialValue);

            }

            if (res.success && res.result) {
                message.success("Tournament is edited.")
                navigate("/events/tournaments");
            }
        }
    };

    const getRewards = async () => {
        const { success, total_count, result } = await getRewardsList({
            tournament_id: location.state.id,
        });
        if (success && total_count > 0) {
            const rewardResults = result.map((r: any) => ({
                placement: `${r.starting_rank} - ${r.ending_rank}`,
                from: r.starting_rank,
                to: r.ending_rank,
                xp: r.xp,
                point: r.coin,
                id: r.id,
                actions: (
                    <>
                        <Button>Del</Button>
                    </>
                )
            }))
            
            setRewards(rewardResults.sort((a:any,b:any)=>a.from - b.from));
        }
    };


    const getTournaments = async () => {
        const { success, total_count, result } = await getTournamentsList({
            filter: {
                studio_id: selectedGame?.studio?.id,
                game_id: selectedGame?.id,
                active: true,
            },
        });
        if (success && total_count > 0) {
            setTournamentsList(result);
        }
    };


    const dateFixer = (t: any) => new Date(t).toLocaleString("en-US", {
        timeZone: "UTC",
    }) + ""


    // eslint-disable-next-line arrow-body-style
    const disabledDate: RangePickerProps["disabledDate"] = (current) => {
        return (current && current < dayjs().endOf('day'))
            || tournamentsList.filter(t => t.id !== location.state.id).findIndex(t => dayjs(current).isBetween(convertUtcToLocal(t.raw_start_time), convertUtcToLocal(t.raw_end_time), 'date', '[)')) !== -1;
    };

    //time disable
    const getDisabledHours = () => {
        var hours = [];
        if (tournamentsList.filter(t => t.id !== location.state.id).findIndex(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day')) !== -1) {
            const tournamentStart = tournamentsList.filter(t => t.id !== location.state.id).find(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_start_time);
            for (let i = dayjs(start).hour(); i < 24; i++) {
                hours.push(i);
            }

        } else if (tournamentsList.filter(t => t.id !== location.state.id).findIndex(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day')) !== -1) {
            const tournamentStart = tournamentsList.filter(t => t.id !== location.state.id).find(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_end_time);
            for (let i = 0; i < dayjs(start).hour(); i++) {
                hours.push(i);
            }
        }
        return hours;
    }

    //time disable
    const getDisabledMinutes = (selectedHour: any) => {
        var minutes = [];
        if (tournamentsList.filter(t => t.id !== location.state.id).findIndex(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day')) !== -1) {
            const tournamentStart = tournamentsList.filter(t => t.id !== location.state.id).find(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_start_time);
            if (dayjs(start).hour() === selectedHour) {
                for (let i = dayjs(start).minute(); i < 60; i++) {
                    minutes.push(i);
                }
            }


        } else if (tournamentsList.filter(t => t.id !== location.state.id).findIndex(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day')) !== -1) {
            const tournamentStart = tournamentsList.filter(t => t.id !== location.state.id).find(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_end_time);
            if (dayjs(start).hour() === selectedHour) {
                for (let i = 0; i <= dayjs(start).minute(); i++) {
                    minutes.push(i);
                }
            }
        }

        return minutes;
    }


    const isTotalRewardExceed = () => {
        
        const totalReward = rewards?.reduce(
            (ac:number, current:any) => {
                const { to, from, point } = current;
                return ac + ((parseFloat(to) - parseFloat(from) + 1) * parseFloat(point))
            },
            0
        ) || 0;

        const { to, from, point } = rewardRow;
        let otherTournamentCosts = 0;
        tournamentsList.forEach(t => {
            const tournamentCost = t?.rewards?.reduce(
                (ac: number, current: any) => {
                    const { ending_rank, starting_rank, coin } = current;
                    return ac + ((ending_rank - starting_rank + 1) * parseFloat(coin))
                },
                0
            );
            otherTournamentCosts += tournamentCost;
        })
        const rewardRowCost = (parseFloat(to) - parseFloat(from) + 1) * parseFloat(point);


        if (selectedGame?.total_coin && totalReward + rewardRowCost <= +selectedGame.total_coin - otherTournamentCosts) return true;
        return false;

    }


    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        { title: <Link to="/">Home</Link> },
                        {
                            title: (
                                <Link to="/events/tournaments">
                                    Tournaments
                                </Link>
                            ),
                        },
                        { title: "Edit tournament" },
                    ]}
                />
                <Button
                    key="submit"
                    htmlType="submit"
                    size="large"
                    type="primary"
                    icon={<CheckCircleOutlined />}
                    disabled={!location.state.id || !rewards || currentStep === 0}
                    onClick={saveTournament}
                >
                    Save
                </Button>
            </PageHeader>
            <PageContent>
                <Row style={{ width: 800 }}>
                    <Col span={4}></Col>
                    <Col span={18}>
                        <Steps
                            style={{ width: 800 }}
                            current={currentStep}
                            onChange={(stp) => {
                                setCurrentStep(stp);
                            }}
                            items={[
                                {
                                    title: "Details",
                                    description: "Set tournament details",
                                },
                                {
                                    title: "Rewards",
                                    description: "Set reward levels",
                                },
                            ]}
                        />
                    </Col>
                </Row>
                <br />
                <br />

                {currentStep === 0 && formData ? (
                    <Row>
                        <Col span={3}></Col>
                        <Col span={12}>
                            <Form
                                layout="vertical"
                                name="newTournamentForm"
                                form={form}
                                onFinish={setTournamentDetails}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                style={{ maxWidth: 800 }}
                                initialValues={formData}
                            >
                                <Form.Item name="name" label="Title">
                                    <Input placeholder="Title of tournament" />
                                </Form.Item>

                                <Form.Item name="start_time" label="Start time">
                                    <DatePicker
                                        format="YYYY-MM-DD HH:mm:ss"
                                        showTime={{
                                            disabledHours: getDisabledHours,
                                            disabledMinutes: getDisabledMinutes,
                                        }}
                                        changeOnBlur={true}
                                        disabledDate={disabledDate}
                                        onSelect={(d) => setSelectedDate(d)}
                                    />
                                </Form.Item>

                                <Form.Item name="duration" label="Length">
                                    <InputNumber addonAfter={"days"} />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Next
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                ) : null}

                {currentStep === 1 && (
                    <Row>
                        <Col span={3}></Col>
                        <Col span={12}>
                            <Table
                                pagination={false}
                                summary={() => (
                                    <>
                                        {addMode && (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}>
                                                    <Row gutter={9}>
                                                        <Col span={12}>
                                                            <Input
                                                                placeholder="From"
                                                                onChange={(
                                                                    e
                                                                ) => setRewardRow(
                                                                    {
                                                                        ...rewardRow,
                                                                        from: e.target.value,
                                                                        placement: `${e.target.value} - ${rewardRow.to}`,
                                                                    }
                                                                )
                                                                }
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <Input
                                                                placeholder="To"
                                                                onChange={(
                                                                    e
                                                                ) => setRewardRow(
                                                                    {
                                                                        ...rewardRow,
                                                                        to: e.target.value,
                                                                        placement: `${rewardRow.from} - ${e.target.value}`,
                                                                    }
                                                                )
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                    <Input
                                                        placeholder="XP"
                                                        onChange={(e) => setRewardRow({
                                                            ...rewardRow,
                                                            xp: e.target.value,
                                                        })
                                                        }
                                                    />
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>
                                                    <Input
                                                        placeholder="Point"
                                                        onChange={(e) => setRewardRow({
                                                            ...rewardRow,
                                                            point: e.target
                                                                .value,
                                                        })
                                                        }
                                                    />
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        )}
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell
                                                index={0}
                                                colSpan={5}
                                            >
                                                {!addMode && (
                                                    <Button
                                                        type="primary"
                                                        icon={<PlusOutlined />}
                                                        onClick={() => {
                                                            setAddMode(true);
                                                        }}
                                                        disabled={addMode}
                                                    >
                                                        Add new row
                                                    </Button>
                                                )}
                                                {addMode && (
                                                    <Space>
                                                        <Button
                                                            type="primary"
                                                            onClick={async() => {
                                                                const addedBefore =
                                                                    checkAddedBefore(
                                                                        rewardRow.from,
                                                                        rewardRow.to
                                                                    );

                                                                if (!isTotalRewardExceed()) {
                                                                    message.warning("For adding more rewards, please allocate more amount for the game.");
                                                                    return false;

                                                                }

                                                                if (+rewardRow.from < 0 || +rewardRow.to < 0) {
                                                                    message.warning("Values can not be less than 1");
                                                                    return false;
                                                                } else if (+rewardRow.from > +rewardRow.to) {
                                                                    message.warning("'From' field can not be bigger than 'To' field");
                                                                    return false;
                                                                } else if (parseFloat(rewardRow.point) <= 0) {
                                                                    message.warning("Point amount should be positive.");
                                                                    return false;
                                                                }
                                                                if (
                                                                    addedBefore
                                                                ) {
                                                                    message.error(
                                                                        "Row conflict, please check your player rows!"
                                                                    );
                                                                    return false;
                                                                }

                                                                await addNewRewards(rewardRow);
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setAddMode(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Space>
                                                )}
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                )}
                                columns={[
                                    {
                                        title: "Winner Count",
                                        dataIndex: "placement",
                                        key: "placement",
                                        width: 250,
                                        render: (_, record) => {
                                            return `${record.from} - ${record.to}`;
                                        },
                                    },
                                    {
                                        title: "XP",
                                        dataIndex: "xp",
                                        key: "xp",
                                        width: 150,
                                    },
                                    {
                                        title: "Cash Points",
                                        dataIndex: "coin",
                                        key: "coin",
                                        render: (_, record) => {
                                            return parseInt(record.point);
                                        },
                                        width: 150,
                                    },
                                    {
                                        title: "Actions",
                                        dataIndex: "actions",
                                        key: "actions",
                                        align: "right",
                                        width: 150,
                                        render: (_, record) => {

                                            return (
                                                <>
                                                    {/* <Button
                                                        size="small"
                                                        icon={<EditOutlined />}
                                                    ></Button>{" "} */}
                                                    <Popconfirm
                                                        title="Delete the reward row"
                                                        description="Are you sure to delete this reward row?"
                                                        onConfirm={() => {
                                                            deleteRewardRow(
                                                                record
                                                            );
                                                        }}
                                                        onCancel={() => { }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            size="small"
                                                            icon={
                                                                <DeleteOutlined />
                                                            }
                                                        ></Button>
                                                    </Popconfirm>
                                                </>
                                            );
                                        },
                                    },
                                ]}
                                dataSource={rewards || []}
                            ></Table>
                        </Col>
                    </Row>
                )}
            </PageContent>
        </Layout>
    );
}
