import {
    Breadcrumb,
    Button,
    Col,
    Empty,
    Popconfirm,
    Row,
    Table,
    Tag,
    Tooltip,
    message,
} from "antd";
import Layout from "../../../../components/Layout";
import PageContent from "../../../../components/PageContent";
import PageHeader from "../../../../components/PageHeader";

import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
    deleteTournament,
    getTournamentsList,
} from "../../../../store/slices/games";
import dayjs from "dayjs";
import {  tagColor, convertUtcToLocal } from "../../../../utils/helpers";
import { coinDivider } from "../../../../utils/constants";

export default function Tournaments() {
    const { ui } = useSelector((state: GlobalState) => state);
    const { user } = useSelector((state: GlobalState) => state.auth);
    const { selectedGame } = ui;
    const navigate = useNavigate();

    const [tableData, setTableData] = useState<object[]>([]);

    const deleteThisTournament = async (id: number) => {
        const { success } = await deleteTournament({ id });
        if (success) {
            message.success("Tournament deleted successfully");
            getTournaments();
        }
    };

    const getTournaments = async () => {
        const resp = await getTournamentsList({
            filter: {
                studio_id: user?.studio?.id,
                game_id: selectedGame?.id,
                active: true,
            },
        });

        if (resp?.success) {
            let fixedTableData: object[] = [];

            await resp.result.map((data: Tournament, index: number) => {
                const date1 = dayjs(data.raw_start_time);
                const date2 = dayjs(data.raw_end_time);
                const tournament_length = date2.diff(date1, "day") + " days";


                const realStatus = data.status === 'completed' ? 'finished' : data.status;

                const showTime = new Date(convertUtcToLocal(data.raw_start_time)).toLocaleString("en-US");
                const usdRewardAmount = data?.total_reward_coin ? +data.total_reward_coin / coinDivider : 0;

                fixedTableData.push({
                    key: index,
                    title: data.name,
                    status: (
                        <Tag
                            color={tagColor(realStatus)}
                            style={{ textTransform: "capitalize" }}
                        >
                            {realStatus}
                        </Tag>
                    ),
                    length: tournament_length,
                    start_time: (
                        <Tooltip title={showTime}>
                            {realStatus === "finished" ? showTime : data.start_time}
                        </Tooltip>
                    ),
                    rewards: `$${usdRewardAmount}`,
                    actions: (
                        <>
                            <Button size="small" icon={<EditOutlined />} disabled={data?.status === "ongoing"} onClick={() => {
                                if (data?.status !== "ongoing") {
                                    navigate(`/events/tournaments/edit/${data.id}`, { state: data })
                                }
                            }}>
                                Edit
                            </Button>
                            {" "}
                            <Popconfirm
                                title="Delete the tournament"
                                description="Are you sure to delete this tournament?"
                                onConfirm={() => {
                                    deleteThisTournament(data.id);
                                }}
                                disabled={data?.status === "ongoing"}
                                onCancel={() => { }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button size="small" icon={<DeleteOutlined />} disabled={data?.status === "ongoing"} >
                                    Delete
                                </Button>
                            </Popconfirm>
                        </>
                    ),
                });
            });

            setTableData(fixedTableData);
        }
    };

    useEffect(() => {
        getTournaments();
    }, []);

    const columns = [
        {
            title: <h3 style={{ color: "black", margin: 0 }}>Title</h3>,
            dataIndex: "title",
            key: "title",
        },
        {
            title: <h3 style={{ color: "black", margin: 0 }}>Status</h3>,
            dataIndex: "status",
            key: "status",
        },
        {
            title: <h3 style={{ color: "black", margin: 0 }}>Start time</h3>,
            dataIndex: "start_time",
            key: "start_time",
        },
        {
            title: <h3 style={{ color: "black", margin: 0 }}>Length</h3>,
            dataIndex: "length",
            key: "length",
        },
        {
            title: <h3 style={{ color: "black", margin: 0 }}>Rewards</h3>,
            dataIndex: "rewards",
            key: "rewards",
        },
        {
            title: <h3 style={{ color: "black", margin: 0 }}>Actions</h3>,
            dataIndex: "actions",
            key: "actions",
        },
    ];

    return (
        <Layout>
            <PageHeader>
                <Row justify={"space-between"} style={{ width: "100%" }}>
                    <Col span={8}>
                        <Breadcrumb
                            items={[
                                {
                                    title: (
                                        <Link to="/game/dashboard">
                                            {selectedGame?.name}
                                        </Link>
                                    ),
                                },
                                { title: "Tournaments" },
                            ]}
                        />
                    </Col>
                    <Col
                        span={16}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Link to="/events/tournaments/add">
                            <Button
                                type="primary"
                                size="large"
                                icon={<PlusOutlined />}
                            >
                                New tournament
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </PageHeader>
            <PageContent>
                {tableData.length === 0 ? (
                    <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 200 }}
                        description={
                            <span>
                                <h2>There are no tournaments added</h2>
                                <p>You can add a new one</p>
                            </span>
                        }
                    >
                        <Link to={"/events/tournaments/add"}>
                            <Button type="primary">Add new tournament</Button>
                        </Link>
                    </Empty>
                ) : (
                    <Table
                        size="large"
                        dataSource={tableData}
                        columns={columns}
                        pagination={false}
                    />
                )}
            </PageContent>
        </Layout>
    );
}
