import "./style.css";
import {
    Avatar,
    Card,
    Slider,
    Row,
    Col,
    Input,
    Space,
    Button,
    message as messagePopup,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { AppDispatch } from "../../../../store";
import { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { getStudioGames, saveGameAllocation, updateGames, setLoading } from "../../../../store/slices/games";
import { useMemo } from "react";
import { InputStatus } from "antd/es/_util/statusUtils";
import { coinDivider } from "../../../../utils/constants";
const { Meta } = Card;

export default function GameBox({ gameInfo }: { gameInfo: Game }) {
    const { user } = useSelector((state: GlobalState) => state.auth);
    const dispatch: AppDispatch = useDispatch();
    const [inputValue, setInputValue] = useState<number>(0);
    const [errorStatus, setErrorStatus] = useState<InputStatus>("");

    useEffect(() => {
        setInputValue(gameInfo.distributed_coin || 0);
    }, [gameInfo]);

    const saveAllocation = async () => {
        if (inputValue < 0 || (!inputValue && inputValue!==0)) {
            messagePopup.warning('Allocated amount can not be negative.');
            setErrorStatus('error');
            return;
        }

        const { success, message } = await saveGameAllocation({
            game_id: +gameInfo.id,
            distributed_coin: inputValue,
        });
      
        if (success) {
            messagePopup.success("Allocation saved successfully.");
            await getGames()
            
        } else {
            messagePopup.error(message)
            setErrorStatus('error');
        } 
    };

    async function getGames() {
        dispatch(setLoading(true));
        const { success, result } = await getStudioGames({
            filter: { studio_id: user?.studio?.id, active: true },
        });

        if (success) {
            dispatch(setLoading(false));
            dispatch(updateGames(result));
        }
    }

    const allocatedGameCoin = useMemo(() => {
        return gameInfo.total_coin
            ? parseFloat("" + gameInfo.total_coin/coinDivider).toFixed(2)
            : 0
    }, [gameInfo.distributed_coin]) 

    return (
        <Card
            cover={
                <div
                    className="cover"
                    style={{
                        backgroundImage: `url(${gameInfo.background_img})`,
                        border: "1px solid #f0f0f0",
                        borderBottom: "none",
                    }}
                ></div>
            }
        >
            <Meta
                avatar={<Avatar src={gameInfo.logo} shape="square" size={64} />}
                title={
                    <div style={{ fontSize: "1.2rem" }}>{gameInfo.name}</div>
                }
                description={
                    <div style={{ padding: "0 5px" }}>
                        <Row gutter={8}>
                            <Col span={24}>
                                <Space.Compact block>
                                    <Input
                                        prefix={
                                            "$"
                                            // <img
                                            //     src="/assets/images/coin.png"
                                            //     width={16}
                                            // />
                                        }
                                        type='number'
                                        min={0}
                                        status={errorStatus}
                                        onFocus={()=>setErrorStatus("")}
                                        onChange={(e) => {
                                            setInputValue(+e.target.value);
                                        }}
                                        defaultValue={allocatedGameCoin}
                                    />
                                    <Button
                                        type="primary"
                                        icon={<SaveOutlined />}
                                        onClick={() => {
                                            saveAllocation();
                                        }}
                                    ></Button>
                                </Space.Compact>
                            </Col>
                        </Row>
                    </div>
                }
            />
        </Card>
    );
}
