import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../../components/Layout";
import PageHeader from "../../../../components/PageHeader";
import Wrapper from "../../../../components/FormElements/Wrapper";
import { useEffect, useState } from "react";
import { CategoryList } from "../../../../config/categoryList";
import { generateSlug } from "../../../../utils/helpers";
import {
    Button,
    Form,
    Input,
    message,
    Select,
    Upload,
    Switch,
    Breadcrumb,
    Steps,
    Space,
    Row,
    Col,
    Popconfirm,
} from "antd";
import {
    CheckCircleOutlined,
    LinkOutlined,
    LoadingOutlined,
    PlusOutlined,
    DownloadOutlined,
    ArrowRightOutlined,
    CopyOutlined,
    FileDoneOutlined,
} from "@ant-design/icons";
import type { RcFile } from "antd/es/upload/interface";
import useForm from "antd/es/form/hooks/useForm";
import { saveNewGame } from "../../../../store/slices/games";
import useKeyGen from "../../../../hooks/useKeyGen";
import CopyToClipboard from "react-copy-to-clipboard";
import { getApiKeys } from "../../../../store/slices/studio";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

export default function NewGame() {
    const [form] = useForm();
    const navigate = useNavigate();
    const { user } = useSelector((state: GlobalState) => state.auth);

    const [iconUrl, setIconUrl] = useState<string>();
    const [iconLoading, setIconLoading] = useState(false);
    const [bannerUrl, setBannerUrl] = useState<string>();
    const [bannerLoading, setBannerLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [androidPlatformSwitch, setAndroidPlatformSwitch] = useState(false);
    const [IOsPlatformSwitch, setIOsPlatformSwitch] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [apiKeyList, setApiKeyList] = useState<{ name: string }[]>();

    const apiKeys = async () => {
        const filter = { studio_id: user?.studio?.id, active: true };
        const resp = await getApiKeys({
            filter,
        });

        setApiKeyList(resp.result);
    };

    useEffect(() => {
        apiKeys();
    }, []);

    function checkValidation() {
        setButtonDisabled(
            form.getFieldsError().filter(({ errors }) => errors.length).length >
                0
        );
    }

    function fixValues(values: any) {
        values.logo = iconUrl;
        values.background_img = bannerUrl;
        values.config = {};
        delete values.banner;
        delete values.icon;
        if (values.android) {
            values.is_android = true;
            values.config.android = {
                active: true,
                package_name: values.android_package_name,
                deeplink: values.android_deeplink,
                store_page_url: values.android_store_page_url,
            };
        }
        if (values.ios) {
            values.is_ios = true;
            values.config.ios = {
                active: true,
                package_name: values.ios_package_name,
                deeplink: values.ios_deeplink,
                store_page_url: values.ios_store_page_url,
            };
        }
        delete values.android_package_name;
        delete values.android_deeplink;
        delete values.android_store_page_url;
        delete values.android;

        delete values.ios_package_name;
        delete values.ios_deeplink;
        delete values.ios_store_page_url;
        delete values.ios;

        values.slug = generateSlug(values.name);

        return values;
    }

    const onFinish = async (values: any) => {
        /// fix values
        const vals = fixValues(values);

        /// save game into the database
        const { success } = await saveNewGame(vals);

        /// and navigate to /games if success === true
        if (success) {
            message.success("Game saved successfully");
            setCurrentStep((step) => step + 1);
            // navigate("/games/manage-games");
        } else {
            message.error("Failed.");
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        message.error("Please fill required fields!");
    };

    const gameForm = () => {
        return (
            <Form
                form={form}
                name="newGameForm"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={{ maxWidth: 800 }}
                onChange={checkValidation}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input game name!",
                        },
                    ]}
                >
                    <Input placeholder="Type name here.." size="large" />
                </Form.Item>

                {/* <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: "Please input game description!",
                        },
                    ]}
                >
                    <TextArea
                        rows={4}
                        placeholder="Type description here.."
                        maxLength={500}
                        showCount
                        size="large"
                    />
                </Form.Item> */}

                <Form.Item
                    label="Game Icon"
                    name="icon"
                    help={"1024x1024, JPEG or JPG"}
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: "Please upload game icon!",
                    //     },
                    // ]}
                >
                    <Upload
                        name="icon"
                        listType="picture-card"
                        showUploadList={false}
                        accept="image/jpeg"
                        beforeUpload={(info) => {
                            getBase64(info, (url) => {
                                setIconLoading(false);
                                setIconUrl(url);
                                return;
                            });
                        }}
                    >
                        {iconUrl ? (
                            <img
                                src={iconUrl}
                                alt="Game Icon"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            <div>
                                {iconLoading ? (
                                    <LoadingOutlined />
                                ) : (
                                    <PlusOutlined />
                                )}
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        )}
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="Game Banner"
                    name="banner"
                    help={"1000x600, JPEG or JPG"}
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: "Please upload game banner!",
                    //     },
                    // ]}
                >
                    <Upload
                        className="gameBannerUploader"
                        name="banner"
                        listType="picture-card"
                        showUploadList={false}
                        accept="image/jpeg"
                        beforeUpload={(info) => {
                            getBase64(info, (url) => {
                                setBannerLoading(false);
                                setBannerUrl(url);
                                return;
                            });
                        }}
                    >
                        {bannerUrl ? (
                            <img
                                src={bannerUrl}
                                alt="Game Banner"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            <div>
                                {bannerLoading ? (
                                    <LoadingOutlined />
                                ) : (
                                    <PlusOutlined />
                                )}
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        )}
                    </Upload>
                </Form.Item>

                {/* <Form.Item name="category_ids" label="Categories">
                    <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select game categories"
                        onChange={() => {}}
                        optionLabelProp="label"
                        size="large"
                        allowClear
                    >
                        {CategoryList.map((category) => (
                            <Option
                                key={category.id}
                                value={category.id}
                                label={category.name}
                            >
                                {category.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item> */}

                <Form.Item
                    name="android"
                    label="Android"
                    valuePropName="checked"
                >
                    <Switch onChange={setAndroidPlatformSwitch} />
                </Form.Item>

                <Form.Item
                    name="android_package_name"
                    label="Package Name"
                    rules={[
                        {
                            required: androidPlatformSwitch,
                            message: "Please input Android package name!",
                        },
                    ]}
                >
                    <Input placeholder="com.example.app123" size="large" />
                </Form.Item>

                {/* <Form.Item
                    name="android_deeplink"
                    label="Deeplink"
                    rules={[
                        {
                            required: androidPlatformSwitch,
                            message: "Please input Android deeplink!",
                        },
                    ]}
                >
                    <Input
                        placeholder="https://play.google.com/store/apps/details?id=com.example.app123"
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    name="android_store_page_url"
                    label="Store Page URL"
                    rules={[
                        {
                            required: androidPlatformSwitch,
                            message: "Please input Android store page url!",
                        },
                    ]}
                >
                    <Input
                        placeholder="https://play.google.com/store/apps/details?id=com.example.app123"
                        size="large"
                    />
                </Form.Item> */}

                <Form.Item name="ios" label="IOS" valuePropName="checked">
                    <Switch onChange={setIOsPlatformSwitch} />
                </Form.Item>

                <Form.Item
                    name="ios_package_name"
                    label="Package Name"
                    rules={[
                        {
                            required: IOsPlatformSwitch,
                            message: "Please input IOs package name!",
                        },
                    ]}
                >
                    <Input placeholder="com.example.app123" size="large" />
                </Form.Item>

                {/* <Form.Item
                    name="ios_deeplink"
                    label="Deeplink"
                    rules={[
                        {
                            required: IOsPlatformSwitch,
                            message: "Please input IOs deeplink!",
                        },
                    ]}
                >
                    <Input
                        placeholder="https://play.google.com/store/apps/details?id=com.example.app123"
                        size="large"
                    />
                </Form.Item>

                <Form.Item
                    name="ios_store_page_url"
                    label="Store Page URL"
                    rules={[
                        {
                            required: IOsPlatformSwitch,
                            message: "Please input IOs store page url!",
                        },
                    ]}
                >
                    <Input
                        placeholder="https://play.google.com/store/apps/details?id=com.example.app123"
                        size="large"
                    />
                </Form.Item> */}
                <Form.Item wrapperCol={{ span: 8, offset: 6 }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={<CheckCircleOutlined />}
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    const firebaseSetup = () => {
        return (
            <>
                <p style={{ maxWidth: 600 }}>
                    If you have not installed Firebase SDK and created a
                    Firebase project for your game, please read steps from
                    documents in order to import and authenticate your game to
                    Firebase.
                </p>
                <br />
                <Space>
                    <a href="https://docs.ratic.io/" target="_blank">
                        <Button type="primary" size="large">
                            docs.ratic.io <LinkOutlined />
                        </Button>
                    </a>
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                            setCurrentStep((step) => step + 1);
                        }}
                    >
                        Already installed <ArrowRightOutlined />
                    </Button>
                </Space>
            </>
        );
    };

    const downloadSdk = () => {
        return (
            <>
                <br />
                <Button icon={<DownloadOutlined />} target="_blank" href="https://sdk.ratic.network/ratic-sdk-v0.1.0.unitypackage" type="primary" size="large">
                    Download ratic.unitypackage <LinkOutlined />
                </Button>
                <br />
                <br />
                <Steps
                    progressDot
                    current={1}
                    style={{ maxWidth: 600 }}
                    direction="vertical"
                    items={[
                        {
                            title: (
                                <span style={{ color: "black" }}>
                                    1. Download the RaticSDK Unity package.
                                </span>
                            ),
                            description: `This package can be downloaded from the Dashboard later. Ensure that you download the latest version to benefit from recent updates and fixes.`,
                            status: "finish",
                        },
                        {
                            title: (
                                <span style={{ color: "black" }}>
                                    2. Open your game in Unity.
                                </span>
                            ),
                            description:
                                "Ensure you have the appropriate Unity version (2021.3 LTS) that is compatible with RaticSDK package.",
                            status: "finish",
                        },
                        {
                            title: (
                                <span
                                    style={{ color: "black" }}
                                >{`3. Navigate to Assets > Import package > Custom package`}</span>
                            ),
                            description: (
                                <div>
                                    {`And select the downloaded RaticSDK Unity
                                    package. This will bring up an import
                                    dialog, which allows you to choose the
                                    assets you want to import. Select all assets
                                    and click "Import".`}
                                    <br />
                                    <br />
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={() => {
                                            setCurrentStep((step) => step + 1);
                                        }}
                                    >
                                        Next <ArrowRightOutlined />
                                    </Button>
                                </div>
                            ),
                            status: "finish",
                        },
                    ]}
                />
            </>
        );
    };

    const [newKeyNick, setNewKeyNick] = useState<string>();
    const { createKey } = useKeyGen();
    const [keyMsg, setKeyMsg] = useState(false);
    const [key, setKey] = useState<string>("");
    const [keySecret, setKeySecret] = useState<string>("");
    const sdkKeys = () => {
        if (!apiKeyList) return null;
        return (
            <h3>
                SDK Key & Secret
                <br />
                <br />
                <Row>
                    <Col span={7}>Choose existing</Col>
                    <Col span={3}></Col>
                    <Col span={10}>Create new key</Col>
                </Row>
                <Row>
                    <Col span={7}>
                        <Select
                            style={{ width: "100%" }}
                            placeholder={"Select a key"}
                            options={apiKeyList.map((apiKey) => ({
                                value: apiKey.name,
                                label: apiKey.name,
                            }))}
                        />
                    </Col>
                    <Col span={3} style={{ textAlign: "center" }}>
                        {" "}
                        - OR -
                    </Col>
                    <Col span={10}>
                        {keyMsg ? (
                            <>
                                <Row style={{ width: 800 }}>
                                    <Col>
                                        <h3 style={{ color: "black" }}>
                                            New key generated
                                        </h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Your secret can only be copied once,
                                        don't forget to write it down. If you
                                        leave or refresh the page you will not
                                        be able to copy it again.
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Space>
                                            <CopyToClipboard
                                                text={key}
                                                onCopy={() => {
                                                    message.success(
                                                        "Copied to clipboard"
                                                    );
                                                }}
                                            >
                                                <Button
                                                    type="primary"
                                                    icon={<CopyOutlined />}
                                                >
                                                    Copy Key
                                                </Button>
                                            </CopyToClipboard>
                                            <CopyToClipboard
                                                text={keySecret}
                                                onCopy={() => {
                                                    message.success(
                                                        "Copied to clipboard"
                                                    );
                                                }}
                                            >
                                                <Button
                                                    type="primary"
                                                    icon={<CopyOutlined />}
                                                >
                                                    Copy Secret
                                                </Button>
                                            </CopyToClipboard>
                                        </Space>
                                    </Col>
                                </Row>
                                <br />
                                <br />
                            </>
                        ) : (
                            <Popconfirm
                                title="Generate New Key"
                                description={
                                    <Input
                                        placeholder="Nickname"
                                        onChange={(e) => {
                                            setNewKeyNick(e.target.value);
                                        }}
                                    />
                                }
                                onConfirm={async () => {
                                    if (newKeyNick && newKeyNick !== "") {
                                        const resp = await createKey(
                                            newKeyNick
                                        );
                                        if (resp.success) {
                                            setKeySecret(
                                                resp.result.api_secret
                                            );
                                            setKey(resp.result.api_key);
                                            message.success(
                                                "New key generated"
                                            );
                                            setKeyMsg(true);
                                        }
                                    } else {
                                        message.error(
                                            "Please type a nickname for the new key"
                                        );
                                    }
                                }}
                                okText="Generate"
                                cancelText="Cancel"
                            >
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    size="large"
                                >
                                    Create new key
                                </Button>
                            </Popconfirm>
                        )}
                    </Col>
                </Row>
                <br />
                <br />
                <Space direction="vertical">
                    <Button
                        size="large"
                        type={keyMsg ? "primary" : "default"}
                        onClick={() => {
                            setCurrentStep((step) => step + 1);
                        }}
                    >
                        Next <ArrowRightOutlined />
                    </Button>
                </Space>
            </h3>
        );
    };

    const allSet = () => {
        return (
            <>
                <h3>
                    You are all set!.
                    <br />
                    <br />
                    Make sure to check out the{" "}
                    <a href="https://docs.ratic.io" target="_blank">
                        documentation
                    </a>{" "}
                    to learn how to get started with Ratic SDK LiveOps features
                    and functions.
                </h3>
                <br />
                <Link to="/games/manage-games">
                    <Button
                        type="primary"
                        size="large"
                        icon={<FileDoneOutlined />}
                    >
                        Finish Setup
                    </Button>
                </Link>
            </>
        );
    };

    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        { title: <Link to="/">Home</Link> },
                        { title: <Link to="/games/manage-games">Games</Link> },
                        { title: "Add new game" },
                    ]}
                />
            </PageHeader>
            <Wrapper>
                <>
                    <Steps
                        direction="vertical"
                        current={currentStep}
                        onChange={(step) => {
                            setCurrentStep(step);
                        }}
                        items={[
                            {
                                title: (
                                    <span style={{ color: "black" }}>
                                        Create game
                                    </span>
                                ),
                                description: currentStep === 0 && gameForm(),
                            },
                            // {
                            //     title: (
                            //         <span style={{ color: "black" }}>
                            //             Firebase Setup (Required for Google
                            //             Sign-in)
                            //         </span>
                            //     ),
                            //     description:
                            //         currentStep === 1 && firebaseSetup(),
                            // },
                            {
                                title: (
                                    <span style={{ color: "black" }}>
                                        Download and add Ratic SDK package to
                                        your Unity game
                                    </span>
                                ),
                                description: currentStep === 1 && downloadSdk(),
                            },
                            {
                                title: (
                                    <span style={{ color: "black" }}>
                                        Create your SDK key & secret
                                    </span>
                                ),
                                description: currentStep === 2 && sdkKeys(),
                            },
                            {
                                title: (
                                    <span style={{ color: "black" }}>
                                        Next steps
                                    </span>
                                ),
                                description: currentStep === 3 && allSet(),
                            },
                        ]}
                    />
                </>
            </Wrapper>
        </Layout>
    );
}
