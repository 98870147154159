import {
    Breadcrumb,
    Button,
    Col,
    Input,
    Popover,
    Row,
    Space,
    Table,
} from "antd";
import PageContent from "../../../../components/PageContent";
import Layout from "../../../../components/Layout";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import PageHeader from "../../../../components/PageHeader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { countries } from "../../../../config/countries";
import { regionFactorsList } from "../../../../config/regionFactors";
import { useEffect, useState } from "react";
import { regionFactors } from "../../../../store/slices/games";

export default function RegionFactors() {
    const { ui } = useSelector((state: GlobalState) => state);
    const { selectedGame } = ui;
    const [list, setList] = useState<object[]>([]);

    const getList = async () => {
        if (selectedGame) {
            const { success, total_count, result } = await regionFactors.List(
                +selectedGame.id
            );
            console.log("REGION FACTORS FROM DB", result);
            if (success && total_count > 0) {
                setList(result);
            }
        }
    };

    useEffect(() => {
        if (selectedGame) {
            getList();
        }
    }, [selectedGame]);

    const dataSource = Object.entries(regionFactorsList).map((keyVal) => {
        const [country, value] = keyVal;
        return {
            key: country,
            region: country,
            factor: value,
        };
    });
    // const dataSource22 = countries.map((country) => {
    //     return {
    //         key: country.numericCode,
    //         region: country.name,
    //         factor: 1.0,
    //         actions: (
    //             <>
    //                 <Button type="default" size="small" icon={<EditOutlined />}>
    //                     Edit
    //                 </Button>
    //             </>
    //         ),
    //     };
    // });

    return (
        <Layout>
            <PageHeader>
                <Row style={{ width: "100%" }}>
                    <Col span={12}>
                        <Breadcrumb
                            items={[
                                {
                                    title: (
                                        <Link to="/game/dashboard">
                                            {selectedGame?.name}
                                        </Link>
                                    ),
                                },
                                { title: "Region factors" },
                            ]}
                        />
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <Popover
                            placement="leftTop"
                            content={
                                <p style={{ width: 300 }}>
                                    Region factors helps you optimize your
                                    retention cost for different regions. To
                                    give an example when you Whatever amount you
                                    enter as a Cash Point reward in the
                                    dashboard, it will be shown to the end user
                                    after being multiplied by their region
                                    factor.
                                </p>
                            }
                            title=""
                        >
                            <Button
                                type="default"
                                icon={<InfoCircleOutlined />}
                            >
                                Learn more
                            </Button>
                        </Popover>
                    </Col>
                </Row>
            </PageHeader>
            <PageContent>
                <Table
                    size="large"
                    dataSource={dataSource}
                    columns={[
                        {
                            title: (
                                <h3 style={{ color: "black", margin: 0 }}>
                                    Region
                                </h3>
                            ),
                            dataIndex: "region",
                            key: "region",
                        },
                        {
                            title: (
                                <h3 style={{ color: "black", margin: 0 }}>
                                    Factor
                                </h3>
                            ),
                            dataIndex: "factor",
                            key: "factor",
                            width: 100,
                            sorter: (a: any, b: any) => a.factor - b.factor,
                            render: (_, record) => {
                                return <>{record.factor}</>;
                            },
                        },
                        {
                            title: (
                                <h3 style={{ color: "black", margin: 0 }}>
                                    Actions
                                </h3>
                            ),
                            dataIndex: "actions",
                            key: "actions",
                            width: 100,
                            render: (_, record) => {
                                return (
                                    <>
                                        <Popover
                                            placement="leftTop"
                                            content={
                                                <Space direction="vertical">
                                                    <Input
                                                        defaultValue={
                                                            record.factor
                                                        }
                                                    />
                                                    <Button type="primary">
                                                        Update
                                                    </Button>
                                                </Space>
                                            }
                                            title={
                                                <span
                                                    style={{ color: "black" }}
                                                >
                                                    {`Edit region factor of ${record.region}`}
                                                </span>
                                            }
                                            trigger={"click"}
                                        >
                                            <Button
                                                type="default"
                                                size="small"
                                                icon={<EditOutlined />}
                                            >
                                                Edit
                                            </Button>
                                        </Popover>
                                    </>
                                );
                            },
                        },
                    ]}
                    pagination={false}
                />
            </PageContent>
        </Layout>
    );
}
