import {
    Breadcrumb,
    Button,
    Col,
    Row,
    Upload, Modal, Popover, message as messagePopup
} from "antd";
import Layout from "../../../../components/Layout";
import PageContent from "../../../../components/PageContent";
import PageHeader from "../../../../components/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import './style.css'
import {
    InfoCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useSelector } from "react-redux";
import { useState, useRef, useEffect, ReactHTMLElement, ReactElement } from "react";
import { Content } from "antd/es/layout/layout";
import { Space, Typography } from 'antd';
import { getProfilePictures, updateProfilePictures } from "../../../../store/slices/games";
import FormData from 'form-data';
import { textChangeRangeIsUnchanged } from "typescript";




const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });



// function getBase64Image(src:string) {
//     const img: any = <img id="imageId" src={src} />
//     console.log(img)
//     var canvas:any = document.createElement("canvas");
//     canvas.width = img?.width;
//     canvas.height = img?.height;
//     var ctx:any = canvas.getContext("2d");
//     ctx.drawImage(img, 0, 0);
//     var dataURL = canvas.toDataURL("image/jpg");
//     return dataURL;
// }


const convertImageToBase64=(imgUrl:string, callback:any)=> {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx:any = canvas.getContext('2d');
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataUrl = canvas.toDataURL();
        callback && callback(dataUrl)
    }
    image.src = imgUrl;
}


export default function Customization() {
    const { ui } = useSelector((state: GlobalState) => state);
    const { selectedGame } = ui;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [profilePictures, setProfilePictures] = useState<UploadFile[]>([]);
    const navigate = useNavigate();

    const uploadRef = useRef();


    const { Text } = Typography;

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file: UploadFile) => {
   
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }


        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    function convertImageToBase64Async(imgUrl:string) {
    return new Promise(resolve => convertImageToBase64(imgUrl, resolve))
 } 

    const handleChange: UploadProps['onChange'] =  ({ fileList: newFileList }) => {
        const newFileLength = newFileList.length;
        const prevStateLength = profilePictures.length;
        // var proxyUrl = 'https://cors-anywhere.herokuapp.com/';
        if (newFileList.length <= 9) {
            if (newFileLength > prevStateLength) {
                const arr:any[]= []
                newFileList.forEach((i) => {
                    if (i.url) {
                          convertImageToBase64( i.url, (e: string) => arr.push(e));
                    } else if(i.originFileObj) {
                        getBase64(i.originFileObj).then(resp => {
                          arr.push(resp) 
                       });

                    }
                })
             
                 console.log(arr.length);
                
                //  await uploadProfilePictures(arr);
                

            }

            setProfilePictures(newFileList);
        } else {
            messagePopup.warning('You can not upload more than 9 images. Please delete an image before add.')
            return;
        }
    }



    useEffect(() => {
        getAvatars()
    }

        , []);



    const getAvatars = async () => {

        const { images } = await getProfilePictures({ game_id: Number(selectedGame?.id) || 0 });


        const avatars = images.map((i: string) => ({ name: i.split("/").pop()?.at(0), url: i }))

        setProfilePictures(avatars);

    }
    const uploadProfilePictures = async (arr: any) => {
        const { success, message } = await updateProfilePictures({
            id: Number(selectedGame?.id) || 0,
            images: arr,
        })

        if (success) {
            messagePopup.success('Profile Pictures are uploaded.');

        } else {

            messagePopup.error(message);
            setProfilePictures([]);
        }
    }


    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        { title: <Link to="/">{selectedGame?.name || 'Home'}</Link> },
                        { title: "Customization" },
                    ]}
                />

                <Popover
                    placement="leftTop"
                    content={
                        <p style={{ width: 300 }}>
                            You can customize profile pictures of your game from here, just add them and enjoy!
                        </p>
                    }
                    title=""
                >
                    <Button type="default" icon={<InfoCircleOutlined />}>
                        Learn more
                    </Button>
                </Popover>

                {/* </Link> */}
            </PageHeader>
            <PageContent>
                <Row className="wrapper-layout">

                    <Col span={12}>

                        <Content className="photos">
                            <Upload
                                // action=""
                                listType="picture-card"
                                fileList={profilePictures}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                ref={uploadRef}
                                disabled={profilePictures.length > 9}
                                accept="image/jpeg"
                                beforeUpload={() =>false}

                                prefixCls="upload"
                                multiple={true}
                            >
                                {profilePictures.length <= 9 &&
                                    <Button className="upload-button" icon={<PlusOutlined />} type="primary">

                                        AVATAR
                                    </Button>}

                            </Upload>

                            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>

                        </Content>
                        <p className="warning-avatar">  <InfoCircleOutlined />  Type:JPG / Size:500x500 / Limit:9 Avatars</p>
                    </Col>
                    {/* <Col span={8} className="rules">
                        <Content>
                         
                           
                        </Content>
                    </Col> */}
                </Row>
            </PageContent>
        </Layout>
    );
}
