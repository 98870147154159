export const regionFactorsList = {
    Afghanistan: 0.12,
    Albania: 0.1,
    Algeria: 0.08,
    "American Samoa": 0.15,
    Andorra: 0.13,
    Angola: 0.12,
    Anguilla: 0.19,
    Antarctica: 0.06,
    "Antigua and Barbuda": 0.19,
    Argentina: 0.11,
    Armenia: 0.09,
    Aruba: 0.18,
    Australia: 0.46,
    Austria: 0.36,
    Azerbaijan: 0.08,
    Bahrain: 0.23,
    Bangladesh: 0.11,
    Barbados: 0.13,
    Belarus: 0.08,
    Belgium: 0.35,
    Belize: 0.16,
    Benin: 0.14,
    Bermuda: 0.17,
    Bhutan: 0.12,
    Bolivia: 0.11,
    "Bosnia and Herzegovina": 0.07,
    Botswana: 0.15,
    Brazil: 0.2,
    "British Indian Ocean Territory": 0.11,
    "British Virgin Islands": 0.27,
    Brunei: 0.28,
    Bulgaria: 0.11,
    "Burkina Faso": 0.14,
    Burundi: 0.15,
    Cambodia: 0.14,
    Cameroon: 0.13,
    Canada: 0.49,
    "Cape Verde": 0.14,
    "Caribbean Netherlands": 0.14,
    "Cayman Islands": 0.3,
    "Central African Republic": 0.12,
    Chad: 0.17,
    Chile: 0.19,
    China: 0.1,
    Colombia: 0.13,
    Comoros: 0.13,
    "Cook Islands": 0.15,
    "Costa Rica": 0.19,
    "Cote d’Ivoire": 0.15,
    Croatia: 0.11,
    Curacao: 0.16,
    Cyprus: 0.14,
    Czechia: 0.17,
    "Democratic Republic of the Congo": 0.15,
    Denmark: 0.28,
    Djibouti: 0.11,
    Dominica: 0.16,
    "Dominican Republic": 0.16,
    Ecuador: 0.13,
    Egypt: 0.1,
    "El Salvador": 0.13,
    "Equatorial Guinea": 0.12,
    Eritrea: 0.14,
    Estonia: 0.22,
    Eswatini: 0.15,
    Ethiopia: 0.14,
    "Falkland Islands (Islas Malvinas)": 0.09,
    "Faroe Islands": 0.13,
    "Federated States of Micronesia": 0.12,
    Fiji: 0.16,
    Finland: 0.35,
    France: 0.28,
    "French Guiana": 0.19,
    "French Polynesia": 0.13,
    Gabon: 0.11,
    Georgia: 0.1,
    Germany: 0.43,
    Ghana: 0.14,
    Gibraltar: 0.11,
    Greece: 0.11,
    Greenland: 0.15,
    Grenada: 0.14,
    Guadeloupe: 0.18,
    Guam: 0.18,
    Guatemala: 0.14,
    Guernsey: 0.16,
    Guinea: 0.12,
    "Guinea-Bissau": 0.14,
    Guyana: 0.15,
    Haiti: 0.09,
    Honduras: 0.13,
    "Hong Kong": 0.33,
    Hungary: 0.17,
    Iceland: 0.22,
    India: 0.13,
    Indonesia: 0.12,
    Iraq: 0.09,
    Ireland: 0.29,
    Israel: 0.22,
    Italy: 0.25,
    Jamaica: 0.15,
    Japan: 0.28,
    Jersey: 0.16,
    Jordan: 0.12,
    Kazakhstan: 0.11,
    Kenya: 0.2,
    Kiribati: 0.16,
    Kosovo: 0.09,
    Kuwait: 0.21,
    Kyrgyzstan: 0.09,
    Laos: 0.11,
    Latvia: 0.3,
    Lebanon: 0.13,
    Lesotho: 0.16,
    Liberia: 0.19,
    Libya: 0.1,
    Liechtenstein: 0.26,
    Lithuania: 0.15,
    Luxembourg: 0.2,
    Macao: 0.13,
    Madagascar: 0.09,
    Malawi: 0.17,
    Malaysia: 0.16,
    Maldives: 0.13,
    Mali: 0.11,
    Malta: 0.19,
    "Marshall Islands": 0.11,
    Martinique: 0.19,
    Mauritania: 0.1,
    Mauritius: 0.14,
    Mayotte: 0.16,
    Mexico: 0.18,
    Moldova: 0.09,
    Monaco: 0.19,
    Mongolia: 0.11,
    Montenegro: 0.08,
    Montserrat: 0.18,
    Morocco: 0.12,
    Mozambique: 0.17,
    "Myanmar (Burma)": 0.1,
    Namibia: 0.18,
    Nauru: 0.36,
    Nepal: 0.1,
    Netherlands: 0.29,
    "Netherlands Antilles": 0.24,
    "New Caledonia": 0.2,
    "New Zealand": 0.41,
    Nicaragua: 0.12,
    Niger: 0.11,
    Nigeria: 0.21,
    "Norfolk Island": 0.23,
    "North Macedonia": 0.09,
    "Northern Mariana Islands": 0.15,
    Norway: 0.29,
    Oman: 0.17,
    Pakistan: 0.11,
    Palau: 0.15,
    Palestine: 0.09,
    Panama: 0.18,
    "Papua New Guinea": 0.2,
    Paraguay: 0.13,
    Peru: 0.13,
    Philippines: 0.14,
    Poland: 0.19,
    Portugal: 0.2,
    "Puerto Rico": 0.22,
    Qatar: 0.2,
    "Republic of the Congo": 0.18,
    Reunion: 0.13,
    Romania: 0.15,
    Russia: 0.15,
    Rwanda: 0.16,
    "Saint Helena, Ascension and Tristan da Cunha": 0.09,
    "Sint Maarten": 0.18,
    Slovakia: 0.18,
    Slovenia: 0.13,
    "Solomon Islands": 0.14,
    Somalia: 0.14,
    "South Africa": 0.34,
    "South Korea": 0.16,
    Spain: 0.23,
    "Sri Lanka": 0.09,
    Suriname: 0.12,
    "Svalbard and Jan Mayen": 0.13,
    Sweden: 0.31,
    Switzerland: 0.47,
    Taiwan: 0.12,
    Tajikistan: 0.06,
    Tanzania: 0.2,
    Thailand: 0.15,
    "The Bahamas": 0.2,
    "The Gambia": 0.13,
    "Timor-Leste": 0.11,
    Togo: 0.12,
    Tonga: 0.24,
    "Trinidad and Tobago": 0.13,
    Tunisia: 0.1,
    Turkey: 0.09,
    Turkmenistan: 0.08,
    "Turks and Caicos Islands": 0.22,
    Tuvalu: 0.33,
    "U.S. Virgin Islands": 0.25,
    Uganda: 0.22,
    Ukraine: 0.09,
    "United Arab Emirates": 0.35,
    "United Kingdom": 0.35,
    "United States": 0.68,
    Uruguay: 0.14,
    Uzbekistan: 0.1,
    Vanuatu: 0.23,
    "Vatican City": 0.68,
    Venezuela: 0.08,
    Vietnam: 0.1,
    "Western Sahara": 0.09,
    Yemen: 0.1,
    Zambia: 0.16,
    Zimbabwe: 0.15,
};
