import { Link } from "react-router-dom";
import Layout from "../../../../components/Layout";
import GameBox from "../../components/GameBox";
import PageContent from "../../../../components/PageContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getStudioGames,
    setLoading,
    updateGames,
} from "../../../../store/slices/games";
import { AppDispatch } from "../../../../store";
import { Button, Card, Col, Empty, Row, Skeleton, Statistic } from "antd";
import { selectGame } from "../../../../store/slices/ui";
import { shortNumber } from "../../../../utils/helpers";
import { coinDivider } from "../../../../utils/constants";

export default function SetAllocations() {
    const { games } = useSelector((state: GlobalState) => state);
    // const [gameList, setGameList] = useState<Game[]>([]);
    const dispatch: AppDispatch = useDispatch();
    const { user } = useSelector((state: GlobalState) => state.auth);
    const [totalAllocated, setTotalAllocated] = useState<number>(0);
    const [availableCoins, setAvailableCoins] = useState<number>(0);


    async function getGames() {
        dispatch(setLoading(true));
        const { success, result } = await getStudioGames({
            filter: { studio_id: user?.studio?.id, active: true },
        });

        if (success) {
            dispatch(setLoading(false));
            dispatch(updateGames(result));
        }
    }

    useEffect(() => {
        setTotalAllocated(0);
        dispatch(selectGame(null));
        if (user?.id) {
            getGames();

        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // !games.loading && games.filtered && games.filtered.length && setGameList(games.filtered);
        if (games) {
            setTotalAllocated(0);
            games.list.map(game => {
                if (game.total_coin && typeof game.total_coin === 'string' && parseFloat(game.total_coin) !== 0) {
                    const amount = parseFloat(game.total_coin)
                    setTotalAllocated(allocatedAmount => (allocatedAmount + amount));
                }
            })
            // games.list.map((game) => {
            //     console.log(typeof game.remaining_coin,"OYUN")
            //     totalAllocated === 0 &&
            //         setTotalAllocated((allocated) => {
            //             if (
            //                 game.remaining_coin &&
            //                 typeof game.remaining_coin === "string" &&
            //                 parseFloat(game.remaining_coin) !== 0
            //             ) {
            //                 if (!calculatedGames.includes(+game.id)) {
            //                     setCalculatedGames((ids) => {
            //                         return [...ids, +game.id];
            //                     });
            //                     return (
            //                         allocated + parseFloat(game.remaining_coin)
            //                     );
            //                 } else {
            //                     return allocated;
            //                 }
            //             } else {
            //                 return allocated;
            //             }
            //         });
            // });
        }
    }, [games]);

    useEffect(() => {
        user?.studio.coin &&
            setAvailableCoins(user?.studio.coin - totalAllocated);
    }, [totalAllocated]);

    if (!games.filtered && !totalAllocated && !user?.studio.coin) return null;

    return (
        <Layout>
            <PageContent>
                <br />
                <Row gutter={20}>
                    <Col span={8}>
                        <Card>
                            <Statistic
                                title="Total balance"
                                value={shortNumber((user?.studio.coin as number)/coinDivider)}
                                valueStyle={{
                                    color: "black",
                                }}
                                prefix={
                                    "$"
                                    // <img
                                    //     src={"/assets/images/coin.png"}
                                    //     width={22}
                                    // />
                                }
                                suffix={
                                    null
                                    // <div
                                    //     style={{
                                    //         fontSize: 16,
                                    //         marginLeft: "100%",
                                    //         color: "#919297",
                                    //         width: "100%",
                                    //         textAlign: "right",
                                    //     }}
                                    // >
                                    //     ≈ $
                                    //     {shortNumber(
                                    //         (user?.studio.coin as number) / 400
                                    //     )}
                                    // </div>
                                }
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Statistic
                                title="Allocated"
                                value={shortNumber(totalAllocated/coinDivider)}
                                valueStyle={{
                                    color: "black",
                                }}
                                decimalSeparator=","
                                prefix={
                                    "$"
                                    // <img
                                    //     src={"/assets/images/coin.png"}
                                    //     width={22}
                                    // />
                                }
                                suffix={
                                    null
                                    // <div
                                    //     style={{
                                    //         fontSize: 16,
                                    //         marginLeft: "100%",
                                    //         color: "#919297",
                                    //         width: "100%",
                                    //         textAlign: "right",
                                    //     }}
                                    // >
                                    //     ≈ ${totalAllocated / 400}
                                    // </div>
                                }
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Statistic
                                title="Available"
                                value={shortNumber(availableCoins/coinDivider)}
                                valueStyle={{
                                    color: "black",
                                }}
                                prefix={
                                    "$"
                                    // <img
                                    //     src={"/assets/images/coin.png"}
                                    //     width={22}
                                    // />
                                }
                                suffix={
                                    null
                                    // <div
                                    //     style={{
                                    //         fontSize: 16,
                                    //         marginLeft: "100%",
                                    //         color: "#919297",
                                    //         width: "100%",
                                    //         textAlign: "right",
                                    //     }}
                                    // >
                                    //     ≈ ${availableCoins / 400}
                                    // </div>
                                }
                            />
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row gutter={[20, 20]}>
                    {games.loading ? (
                        <Skeleton active />
                    ) : games.filtered.length === 0 ? (
                        <Col span={24}>
                            <Empty
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={{ height: 200 }}
                                description={
                                    <span>
                                        <h2>There are no games added</h2>
                                        <p>Please add a new game</p>
                                    </span>
                                }
                            >
                                <Link to={"/games/add"}>
                                    <Button type="primary">Add new game</Button>
                                </Link>
                            </Empty>
                        </Col>
                    ) : (
                        <>
                            {games.filtered.map((game: Game, index: number) => (
                                <Col span={8} key={index}>
                                    <GameBox gameInfo={game} />
                                </Col>
                            ))}
                        </>
                    )}
                </Row>
            </PageContent>
        </Layout>
    );
}
