import { Input, Row, Col, Button } from "antd";
import {  useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";


function UserInfoInput({ id, setInput, deleteUser }: { id: string, setInput: Function, deleteUser: any}) {

    const [entered, setEntered]=useState(false)

    return (
        <Row style={{ columnGap: ".75rem", flexFlow: "row" }} onMouseEnter={()=>setEntered(true)} onMouseLeave={()=>setEntered(false)}>
            <Col span={10}>
                <Input type="text" placeholder="Username" style={{ padding: ".4rem" }} onChange={(e) => setInput(e.target.value, "userName", id)}  required/>
            </Col>
            <Col span={10}>
                <Input type="text" placeholder="$75" style={{ textAlign: "right", padding: ".4rem" }}  min={0.00001} onChange={(e) => setInput(e.target.value, "amount",id)} required/>
            </Col>
            {entered && <Button className="adding-user-input-button-danger" onClick={() => deleteUser(id)} danger type="primary" icon={<DeleteOutlined />}></Button>}
            
        </Row>
    );
}

export default UserInfoInput;