import { useDispatch } from "react-redux";
import api from "../../utils/api";
import { getAuthInfo, setUser } from "./auth";

export const getApiKeys = async (payload: any): Promise<any> => {
    const requestBody = {
        filter: payload.filter || {},
    };

    const { success, result, total_count }: APIResponse = await api.post(
        "/studio/studio_key/list",
        requestBody
    );

    console.log("GET API KEYS", result);

    return { success, result, total_count };
};

export const generateApiKey = async (payload: {
    name: string;
}): Promise<any> => {
    const requestBody = {
        name: payload.name,
    };

    const { success, result, total_count }: APIResponse = await api.post(
        "/studio/studio_key/generate",
        requestBody
    );

    return { success, result };
};

export const editApiKey = async (payload: {
    name: string;
    id: number;
}): Promise<any> => {
    const requestBody = {
        name: payload.name,
    };

    const { success, result }: APIResponse = await api.put(
        `/studio/studio_key/edit/${payload.id}`,
        requestBody
    );

    return { success, result };
};

export const deleteApiKey = async (payload: { id: number }): Promise<any> => {
    const { success, result }: APIResponse = await api.delete(
        `/studio/${payload.id}`
    );

    return { success, result };
};

export const editStudio = async (payload: {
    name: string;
    url?: string;
    description?: string;
}): Promise<any> => {
    const { success }: APIResponse = await api.put("/studio/edit", payload);
    const { success: authSuccess, user } = await getAuthInfo();
    return { success, user };
};
