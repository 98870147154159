import {
    Breadcrumb,
    Button,
    Col,
    Empty,
    Popconfirm,
    Row,
    message as messagePopup,
} from "antd";
import Layout from "../../../../../../components/Layout";
import PageContent from "../../../../../../components/PageContent";
import PageHeader from "../../../../../../components/PageHeader";
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate,useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import  { useState } from "react";
import UserInfoInput from "../../components/UserInfoInput";
import './style.css';
import { postCashTournamentUsers } from "../../../../../../store/slices/games";

export default function CashTournamentPayment() {
    const { ui } = useSelector((state: GlobalState) => state);
    const { user } = useSelector((state: GlobalState) => state.auth);
    const { selectedGame } = ui;
    const location = useLocation();
    const navigate = useNavigate();



    const setUserInput = (value: string, type: string, id: string) => {
        const relatedInput = userInputsArray.find(u => u.id === id);
        const relatedInputIndex = relatedInput ? userInputsArray.indexOf(relatedInput) : -1;
        const newUserInputArrayState = userInputsArray;
        if (value && id && type && relatedInput) {
            if (type === "userName") {
                newUserInputArrayState[relatedInputIndex] = { ...relatedInput, userName: value };
            } else if (type === "amount") {
                newUserInputArrayState[relatedInputIndex] = { ...relatedInput, amount: value };
            }
            setUserInputsArray(newUserInputArrayState);
        }
        
   
    };

    const deleteUser = (id: string) => {
        let userInputs = [...userInputsArray];
        const relatedInput = userInputs.findIndex(u => u.id === id);
       
        if (relatedInput === 0) {
            userInputs.shift()
        } else if (relatedInput === userInputs.length - 1) {
            userInputs.pop()
        } else {
            const prevUsers = userInputs.slice(0, relatedInput);
            const nextUsers = userInputs.slice(relatedInput + 1);
            userInputs=[...prevUsers, ...nextUsers]
        }

        setUserInputsArray([...userInputs]);

    }

    const createTenUserInput = () => {
        const arr = [];
        for (let index = 0; index < 10; index++) {
            const elementId = uuidv4();
            arr.push({
                id: elementId,
                userName: "",
                amount: ""
            });
        }
        return arr;
    }
    const initialInputs = createTenUserInput()

    const [userInputsArray, setUserInputsArray] = useState<UserInputNode[]>(initialInputs);



    type UserInputNode = {
        id: string;
        userName: string;
        amount: string;
    }


    const clickAddTenRows = () => {
        const newRows = createTenUserInput();
        setUserInputsArray([...userInputsArray, ...newRows]);
    }
    

    const clickAddNewRow = () => {
        const elementId = uuidv4();
        setUserInputsArray([...userInputsArray, { id: elementId, userName: "", amount: "" }]);

    };






    const makePayment = async() => {
        const usersLastStatus=userInputsArray.filter(user => user.amount && user.userName).map(u=>({username:u.userName,amount:u.amount}));
        const usersWithLackOfField = userInputsArray.filter(user => ((!user.amount && user.userName) || (user.amount && !user.userName)));
        if (usersWithLackOfField.length>0) {
            messagePopup.error('Missing Required fields');
            return;
        } else {
            const { success, message } = await postCashTournamentUsers({
                tournament_id: location.state.id,
                rewards: usersLastStatus
            });

            if (success) {
                messagePopup.success('Payment is successful.');
                setUserInputsArray(initialInputs);
            } else {
                messagePopup.error(message);

            }
        }
}




    return (
        <Layout>
            <PageHeader>
                <Row  className="cash-tournament-wrapper" >
                    <Col span={8}>
                        <Breadcrumb
                            items={[
                                {
                                    title: (
                                        <Link to="/game/dashboard">
                                            {selectedGame?.name}
                                        </Link>
                                    ),
                                },
                                { title: `${location.state.name} / Pay`},
                            ]}
                        />
                    </Col>
                    <Col
                        span={16}
                        className="header-action-button-wrapper"
                    >
                        <Popconfirm
                            title="Cancel"
                            description="Are you sure to cancel payment process?"
                            onConfirm={() => navigate('/events/tournaments') }
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                        <Button
                            // type="secondary"
                            size="large"
                            className="header-action-button"
                        >
                            Cancel
                            </Button>
                        </Popconfirm>
                        <Popconfirm
                            title="Payment Confirm"
                            description="Do you confirm to make payment to users below?"
                            onConfirm={() => makePayment() }
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                        <Button
                            type="primary"
                            size="large"
                            className="header-action-button"
                        >
                            Pay
                            </Button>
                            </Popconfirm>
                    
                     
                    </Col>
                </Row>
            </PageHeader>
            <PageContent>{
                userInputsArray && userInputsArray.length > 0 ? (
                    <>
                   <Col className="cash-tournament-content-wrapper" span={12} >
                    <Row className="cash-user-title">
                        <Col span={10}>
                            <p >Username</p>
                        </Col>
                        <Col span={10}>
                            <p className="cash-user-title-right">Amount</p>  
                        </Col>

                    </Row>

                            {userInputsArray.map(u => <UserInfoInput key={u.id} id={u.id} setInput={setUserInput} deleteUser={deleteUser} />)}
             
                    
                </Col>

           
                <Row className="adding-user-input-button-wrapper" >
               
                        <Button className="adding-user-input-button" onClick={clickAddTenRows}> + Add new 10 winners </Button>
             

             
                        <Button className="adding-user-input-button" onClick={clickAddNewRow}> + Add new winner </Button>
                   
                 
                        </Row>
                    </>) : (<Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 200 }}
                        description={
                            <span>
                                <p>In order to make payment, you must add new row</p>
                            </span>
                        }
                    />) }
               
            </PageContent>
        </Layout>
    );
}
