import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Row,
    Space,
    Steps,
    Table,
    message,
} from "antd";
import Layout from "../../../../../../components/Layout";
import PageContent from "../../../../../../components/PageContent";
import PageHeader from "../../../../../../components/PageHeader";
import { Link, useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { useForm } from "antd/es/form/Form";
import {
    CheckCircleOutlined,
    PlusOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { ReactElement, useEffect, useState } from "react";
import { addDaysToTimestamp, convertUtcToLocal } from "../../../../../../utils/helpers";
import {
    addTournament, getTournamentsList
} from "../../../../../../store/slices/games";
import { useSelector } from "react-redux";
import type { RangePickerProps } from "antd/es/date-picker";


export default function NewTournament() {
    const { ui } = useSelector((state: GlobalState) => state);
    const { selectedGame } = ui;
    const [form] = useForm();
    const navigate = useNavigate();
    const [addMode, setAddMode] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [newTournamentId, setNewTournamentId] = useState<number>();
    const [tournamentsList, setTournamentsList] = useState<any[]>([]);
    // const [totalAllocatedAmount, setTotalAllocatedAmount] = useState<number>(0);
    // const [remainingAmount, setRemainingAmount] = useState<number>(0);
    const [selectedDate, setSelectedDate] = useState<Dayjs>();

    const tournamentInitialValue = {
        name: "",
        start_date_timestamp: "",
        end_date: "",
        status: 2,
        entry_price: 0,
    }
    const [tournamentInfo, setTournamentInfo] = useState<TournamentInfo>(tournamentInitialValue)
    const [rewards, setRewards] = useState<RewardRow[] | null>(null);
    const [rewardRow, setRewardRow] = useState<RewardRow>({
        placement: "0",
        from: "0",
        to: "0",
        xp: "0",
        point: "0",
        actions: (
            <>
                <Button>Del</Button>
            </>
        ),
    });


    type RewardRow = {
        placement: string;
        from: string;
        to: string;
        xp: string;
        point: string;
        actions: ReactElement;
    };

    type TournamentInfo = {
        name: string;
        start_date_timestamp: any;
        end_date: any;
        status: number;
        entry_price: number;
    }


    useEffect(() => {
        getTournaments();
    }, []);
    



    const getTournaments = async () => {
        const { success, total_count, result } = await getTournamentsList({
            filter: {
                studio_id: selectedGame?.studio?.id,
                game_id: selectedGame?.id,
                active: true,
            },
        });
        if (success && total_count > 0) {
            setTournamentsList(result);
        }
    };

    const deleteRewardRow = (placement: string) => {
        const newRewards = rewards?.filter(
            (item) => item.placement !== placement
        );
        setRewards(newRewards || []);
    };

    const setTournamentDetails = (values: any) => {
        if (values.duration <= 0) {
            message.error("Length can not be less than 1");
            return false;
        }

        if (values.name === "") {
            message.error("Tournament title must be added.");
            return false;
        }

        const start_date_timestamp = new Date(values.start_time).getTime();
        const end_date = addDaysToTimestamp(
            start_date_timestamp,
            values.duration
        );

        const newTournamentInfo = {
            name: values.name, start_date_timestamp, end_date, status: 2, entry_price: 0,
        }

        setTournamentInfo(newTournamentInfo)

        if (currentStep === 0) {
            setCurrentStep(1);
        }

        if (currentStep === 1 && !rewards) {
            message.error("Tournament can not be added without rewards");
        }
    }

    const saveTournament = async (values: any) => {
        if (values.duration <= 0) {
            message.error("Length can not be less than 1");
            return false;
        }
        if (rewards === null) {
            message.error("Tournament can not be added without rewards");
            return false;
        }
        const start_date_timestamp = new Date(values.start_time).getTime();
        const end_date = addDaysToTimestamp(
            start_date_timestamp,
            values.duration
        );

        if (currentStep === 0) await setTournamentInfo({ ...tournamentInfo, name: values.name, start_date_timestamp, end_date })

        
        const tournamentRewards = rewards.map(reward => ({ starting_rank: Number(reward.from), ending_rank: Number(reward.to), coin: Number(reward.point), xp: Number(reward.xp) }))


        if (selectedGame) {
            const { name, start_date_timestamp, end_date, status, entry_price } = tournamentInfo;
            if (!name || !start_date_timestamp || !end_date) {
                message.error("Enter the required fields");
                setCurrentStep(0);
                return;
            }
            const res = await addTournament({
                name,
                start_time:
                    new Date(start_date_timestamp).toLocaleString("en-US", {
                        timeZone: "UTC",
                    }) + "",
                end_time:
                    new Date(end_date).toLocaleString("en-US", {
                        timeZone: "UTC",
                    }) + "",
                status,
                entry_price,
                game_id: +selectedGame?.id,
                tournament_rewards: tournamentRewards
            });

            if (!res.success) {
                message.error(res.message);
                setCurrentStep(0);

            }

            if (res.success && res.result) {
                setNewTournamentId(res.result.id);
                message.success("Tournament is added")
                navigate("/events/tournaments");
            }
        }
    };


    // eslint-disable-next-line arrow-body-style
    const disabledDate: RangePickerProps["disabledDate"] = (current) => {
        return (current && current < dayjs().add(-1,'day').endOf('day'))
            || tournamentsList.findIndex(t => dayjs(current).isBetween(convertUtcToLocal(t.raw_start_time), convertUtcToLocal(t.raw_end_time), 'date', '[)')) !== -1;
    };


    //time disable
    const getDisabledHours = () => {
        var hours = [];
        if (dayjs(selectedDate).format('YYYY.MM.DD') === dayjs().format('YYYY.MM.DD')) {
            for (let i = 0; i < dayjs().hour(); i++) {
                hours.push(i);
            }
        }
        
        if (tournamentsList.findIndex(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day')) !== -1)
        {   
            const tournamentStart = tournamentsList.find(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1,"day"), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_start_time);
            for (let i = dayjs(start).hour(); i < 24; i++) {
                hours.push(i);
            }
            
        } else if (tournamentsList.findIndex(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day')) !== -1) {
            const tournamentStart = tournamentsList.find(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_end_time);
            for (let i = 0; i < dayjs(start).hour(); i++) {
                hours.push(i);
            }
        }
        return hours;
    }

    //time disable
    const getDisabledMinutes = (selectedHour: any) => {
        var minutes = [];
        if (selectedHour === dayjs().hour()) {
            for (let i = 0; i <= dayjs().minute(); i++) {
                minutes.push(i);
            }
        }
        if (tournamentsList.findIndex(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day')) !== -1) {
            const tournamentStart = tournamentsList.find(t => dayjs(selectedDate).isSame(dayjs(convertUtcToLocal(t.raw_start_time)).add(-1, "day"), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_start_time);
            if (dayjs(start).hour() === selectedHour) {
                  for (let i = dayjs(start).minute(); i < 60; i++) {
                minutes.push(i);
            }
            }
          

        } else if (tournamentsList.findIndex(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day')) !== -1) {
            const tournamentStart = tournamentsList.find(t => dayjs(selectedDate).isSame(convertUtcToLocal(t.raw_end_time), 'day'))
            const start = convertUtcToLocal(tournamentStart.raw_end_time);
            if (dayjs(start).hour() === selectedHour) {
                for (let i = 0; i <= dayjs(start).minute(); i++) {
                    minutes.push(i);
                }
            }
        }

        return minutes;
    }


    const isTotalRewardExceed = () => {
        const totalReward = rewards?.reduce(
            (ac, current) => {
                const { to, from, point } = current;
                return ac + ((parseFloat(to) - parseFloat(from) + 1) * parseFloat(point))
            },
            0
        ) || 0;
      
        const { to, from, point } = rewardRow;
        let otherTournamentCosts = 0;
        tournamentsList.forEach(t => {
           const tournamentCost= t?.rewards?.reduce(
                (ac:number, current:any) => {
                    const { ending_rank, starting_rank, coin } = current;
                    return ac + ((ending_rank - starting_rank + 1) * parseFloat(coin))
                },
                0
           );
            otherTournamentCosts += tournamentCost;
        })
        const rewardRowCost = (parseFloat(to) - parseFloat(from) + 1) * parseFloat(point);
        
        if (selectedGame?.total_coin && totalReward + rewardRowCost <= +selectedGame.total_coin - otherTournamentCosts) return true;
        return false;

    }



    const checkAddedBefore = (from: string, to: string) => {
        if (!rewards) {
            return false;
        }
        const match = rewards ? rewards.filter((rev: any) => {
            if (+rev.from === +from || +rev.to === +to ||
                (+from <= +rev.from && +to <= +rev.to && +to > +rev.from) || (+from >= +rev.from && +to <= +rev.to) || (+from >= +rev.from && +to >= +rev.to && +from <= +rev.to)) {
                return rev;
            }
        }) : [];

        return match.length > 0;
    };

    return (
        <Layout>
            <PageHeader>
                <Breadcrumb
                    items={[
                        { title: <Link to="/">Home</Link> },
                        {
                            title: (
                                <Link to="/events/tournaments">
                                    Tournaments
                                </Link>
                            ),
                        },
                        { title: "Add new tournament" },
                    ]}
                />
                <Button
                    key="submit"
                    htmlType="submit"
                    onClick={saveTournament}
                    size="large"
                    type="primary"
                    icon={<CheckCircleOutlined />}
                    disabled={currentStep===0}
                >
                    Save
                </Button>
                {/* </Link> */}
            </PageHeader>
            <PageContent>
                <Row style={{ width: 800 }}>
                    <Col span={4}></Col>
                    <Col span={18}>
                        <Steps
                            style={{ width: 800 }}
                            current={currentStep}
                            onChange={(stp) => {
                                setCurrentStep(stp);

                            }}
                            items={[
                                {
                                    title: "Details",
                                    description: "Set tournament details",
                                },
                                {
                                    title: "Rewards",
                                    description: "Set reward levels",
                                },
                            ]}
                        />
                    </Col>
                </Row>
                <br />
                <br />

                {currentStep === 0 && (
                    <Row>
                        <Col span={3}></Col>
                        <Col span={12}>
                            <Form
                                layout="vertical"
                                name="newTournamentForm"
                                form={form}
                                onFinish={setTournamentDetails}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                style={{ maxWidth: 800 }}
                            >
                                <Form.Item name="name" label="Title">
                                    <Input placeholder="Title of tournament" />
                                </Form.Item>

                                <Form.Item name="start_time" label="Start time">
                                    <DatePicker
                                        format="YYYY-MM-DD HH:mm:ss"
                                        disabledDate={disabledDate}
                                        onSelect={(d)=>setSelectedDate(d)}
                                        showTime={{
                                            disabledHours: getDisabledHours,
                                            disabledMinutes: getDisabledMinutes,
                                        }}
                                        changeOnBlur={true}
                                    />
                                </Form.Item>

                                <Form.Item name="duration" label="Length">
                                    <InputNumber addonAfter={"days"} />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Next
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                )}

                {currentStep === 1 && (
                    <Row>
                        <Col span={3}></Col>
                        <Col span={12}>
                            <Table
                                pagination={false}
                                summary={() => (
                                    <>
                                        {addMode && (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}>
                                                    <Row gutter={9}>
                                                        <Col span={12}>
                                                            <Input
                                                                placeholder="From"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setRewardRow(
                                                                        {
                                                                            ...rewardRow,
                                                                            from: e
                                                                                .target
                                                                                .value,
                                                                            placement: `${e.target.value} - ${rewardRow.to}`,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <Input
                                                                placeholder="To"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setRewardRow(
                                                                        {
                                                                            ...rewardRow,
                                                                            to: e
                                                                                .target
                                                                                .value,
                                                                            placement: `${rewardRow.from} - ${e.target.value}`,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                    <Input
                                                        placeholder="XP"
                                                        onChange={(e) => {
                                                            setRewardRow({
                                                                ...rewardRow,
                                                                xp: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>
                                                    <Input
                                                        placeholder="Cash Point"
                                                        onChange={(e) => {
                                                            setRewardRow({
                                                                ...rewardRow,
                                                                point: e.target
                                                                    .value,
                                                            });
                                                        }}
                                                    />
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        )}
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0}>
                                                {!addMode && (
                                                    <Button
                                                        type="primary"
                                                        icon={<PlusOutlined />}
                                                        onClick={() => {
                                                            setAddMode(true);
                                                        }}
                                                        disabled={addMode}
                                                    >
                                                        Add new row
                                                    </Button>
                                                )}
                                                {addMode && (
                                                    <Space>
                                                        <Button
                                                            type="primary"
                                                            onClick={async () => {
                                                                const addedBefore =
                                                                    await checkAddedBefore(
                                                                        rewardRow.from,
                                                                        rewardRow.to
                                                                    );
                                                                if (!isTotalRewardExceed()) {
                                                                    message.warning("For adding more rewards, please allocate more amount for the game.");
                                                                    return false;

                                                                }

                                                                if (+rewardRow.from <= 0 || +rewardRow.to <= 0) {
                                                                    message.warning("Values can not be less than 1");
                                                                    return false;
                                                                } else if (+rewardRow.from > +rewardRow.to) {
                                                                    message.warning("'From' field can not be bigger than 'To' field");
                                                                    return false;
                                                                } else if (parseFloat(rewardRow.point) <= 0) {
                                                                    message.warning("Point amount should be positive.");
                                                                    return false;
                                                                }

                                                                if (
                                                                    addedBefore
                                                                ) {
                                                                    message.error(
                                                                        "Row conflict, please check your player rows!" 
                                                                    );
                                                                    return false;
                                                                }

                                                                if (rewards) {
                                                                    setRewards([
                                                                        ...rewards,
                                                                        rewardRow,
                                                                    ]);
                                                                } else {
                                                                    setRewards([
                                                                        rewardRow,
                                                                    ]);
                                                                }

                                                                // const {
                                                                //     success,
                                                                // } =
                                                                //     await addTournamentReward(
                                                                //         {
                                                                //             starting_rank:
                                                                //                 +rewardRow.from,
                                                                //             ending_rank:
                                                                //                 +rewardRow.to,
                                                                //             xp: +rewardRow.xp,
                                                                //             tournament_id:
                                                                //                 newTournamentId as number,
                                                                //             coin: +rewardRow.point,
                                                                //         }
                                                                //     );
                                                                // if (success) {
                                                                //     setAddMode(
                                                                //         false
                                                                //     );
                                                                //     setRewardRow(
                                                                //         {
                                                                //             placement:
                                                                //                 "0",
                                                                //             from: "0",
                                                                //             to: "0",
                                                                //             xp: "0",
                                                                //             point: "0",
                                                                //             actions:
                                                                //                 (
                                                                //                     <>

                                                                //                     </>
                                                                //                 ),
                                                                //         }
                                                                //     );
                                                                // } else {
                                                                //     message.error(
                                                                //         "New reward rule error!"
                                                                //     );
                                                                // }
                                                            }}
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                setAddMode(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Space>
                                                )}
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                )}
                                columns={[
                                    {
                                        title: "Winner Count",
                                        dataIndex: "placement",
                                        key: "placement",
                                    },
                                    {
                                        title: "XP",
                                        dataIndex: "xp",
                                        key: "xp",
                                        width: 150,
                                    },
                                    {
                                        title: "Cash Point",
                                        dataIndex: "point",
                                        key: "point",
                                        width: 150,
                                    },
                                    {
                                        title: "Actions",
                                        dataIndex: "actions",
                                        key: "actions",
                                        align: "right",
                                        width: 150,
                                        render: (_, record) => {
                                            return (
                                                <>
                                                    {/* <Button
                                                        size="small"
                                                        icon={<EditOutlined />}
                                                    ></Button>{" "} */}
                                                    <Popconfirm
                                                        title="Delete the reward row"
                                                        description="Are you sure to delete this reward row?"
                                                        onConfirm={() => {
                                                            deleteRewardRow(
                                                                `${record.from} - ${record.to}`
                                                            );
                                                        }}
                                                        onCancel={() => { }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            size="small"
                                                            icon={
                                                                <DeleteOutlined />
                                                            }
                                                        ></Button>
                                                    </Popconfirm>
                                                </>
                                            );
                                        },
                                    },
                                ]}
                                dataSource={rewards || []}
                            ></Table>
                        </Col>
                    </Row>
                )}
            </PageContent>
        </Layout>
    );
}
